import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import EzHeader from "pages/common/ez_header";
import EzFooter from "pages/common/ez_footer";
import * as EzFormCheck from 'lib/EzFormCheck';
import {  observer } from 'mobx-react';

import { useAlert } from "react-alert";
import { Beetle as Button } from 'react-button-loaders';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`

const SubmitButton = tw(Button)` mt-8`

/**
 * 고객 문의 화면 입니다. 
 * 
 * created by choi young ho. 
 * 2020.11.16
 */
const ContactUs = observer ( ({contactUsStore})  => {

  const alert = useAlert();

  let [isLoading, setLoading] = useState('');
  let [userEmail, setUserEmail] = useState('');
  let [userEmailErrMsg, setUserEmailErrMsg] = useState('');
  let [userName, setUserName] = useState('');
  let [userNameErrMsg, setUserNameErrMsg] = useState('');
  let [title, setTitle] = useState('');
  let [titleErrMsg, setTitleErrMsg] = useState('');
  let [content, setContent] = useState('');
  let [contentErrMsg, setContentErrMsg] = useState('');


  /**
   * 사용자 입력 값을 체크 하고 
   * 이메일을 전송을 요청 합니다. 
   * 
   * @param {} e 
   */
  const doSubmit = async (e) => {
    e.preventDefault();

    if (!EzFormCheck.doCheckEmailNotNull(
      userEmail, setUserEmail, "이메일 주소를 입력하세요.", setUserEmailErrMsg)) return false;

    if (!EzFormCheck.doCheckStrNotNull(
      userName, setUserName, "이름을 입력 하세요", setUserNameErrMsg)) return false;

    if (!EzFormCheck.doCheckStrNotNull(
      title, setTitle, "제목을 입력 하세요", setTitleErrMsg)) return false;
      
    if (!EzFormCheck.doCheckStrNotNull(
      content, setContent, "고객 문의 내용을 입력 하세요", setContentErrMsg)) return false;

    setLoading('loading');
    await contactUsStore.doSendEmail(userEmail, userName, title, content);
    setLoading('finished');

    alert.show("고객 문의가 접수되었습니다.",{
      title: "알림",
      closeCopy: "닫기",
    });

    return false;
  }




  return (
    <AnimationRevealPage>
      <EzHeader />

      <Container>
        <TwoColumn>
          <ImageColumn>
            <Image imageSrc={EmailIllustrationSrc} />
          </ImageColumn>
          <TextColumn textOnLeft={true}>
            <TextContent>
              <Subheading>고객문의 접수</Subheading>
              <Heading>이지로드 사용에 <span tw="text-primary-500">불편한 사항</span>이나<br /> <span tw="text-primary-500">기능 제안</span> 등을 적어 보내 주세요 </Heading>
              <Description>항상 고객 만족을 위해 노력하고 있습니다. 메일 접수 후 업무 시간 기준 2일 이내로 답변을 드리겠습니다. 감사합니다.</Description>
              <Form>
                <Input type="email" name="email" placeholder="이메일 주소를 입력해 주세요" value={userEmail} onChange={(e) => {
                  let txt = e.target.value;
                  EzFormCheck.doCheckEmailNotNull(txt, setUserEmail, "이메일 주소를 입력하세요.", setUserEmailErrMsg);
                }} />
                {userEmailErrMsg}
                <Input type="text" name="name" placeholder="이름을 입력 하세요" value={userName}  onChange={(e) => {
                  let txt = e.target.value;
                  EzFormCheck.doCheckStrNotNull(txt, setUserName, "이름을 입력 하세요", setUserNameErrMsg);
                }} />
                {userNameErrMsg}
                <Input type="text" name="subject" placeholder="제목을 입력 하세요" value={title} onChange={(e) => {
                  let txt = e.target.value;
                  EzFormCheck.doCheckStrNotNull(txt, setTitle, "제목을 입력 하세요", setTitleErrMsg);
                }} />
                {titleErrMsg}
                <Textarea name="message" placeholder="고객 문의 내용을 입력 하세요" value={content} onChange={(e) => {
                  let txt = e.target.value;
                  EzFormCheck.doCheckStrNotNull(txt, setContent, "고객 문의 내용을 입력 하세요", setContentErrMsg);
                }} />
                {contentErrMsg}
                <SubmitButton onClick={doSubmit} state={isLoading}>접수</SubmitButton>
              </Form>
            </TextContent>
          </TextColumn>
        </TwoColumn>
      </Container>

      <EzFooter />
    </AnimationRevealPage>
  );
});

export default ContactUs;
/*
export default inject(({ contactUsStore }) => ({
  doSendEmail: contactUsStore.doSendEmail,
}))(observer(ContactUs));
*/
