import { observable, action } from 'mobx';
import { makeAutoObservable } from "mobx"
import * as EzHttpUtils from 'common/EzHttpUtils';
import * as EzURLConst from 'common/EzURLConst';
import * as EzFormConst from 'common/EzFormConst';

/**
 * Project : ezweb 
 * 
 * Sign Up 관련 스토어 입니다. 
 * 
 * create by choi young ho. 
 * 2020.11.17
 */
export default class SignUpStore {

    policyVisible = false;
    servicePolicyVisible = false;               // 서비스 이용 약관 보이기 상태 

    
    /**
     * Creates an instance of SignUpStore.
     * root Store 객체를 지정 합니다. 
     * `    
     * @param {*} root
     * @memberof SignUpStore
     */
    constructor(root) {
        this.root = root;
        makeAutoObservable(this);
    }

    setPolicyVisible (visible){
        this.policyVisible = visible;
    }

    /**
     * 서비스 이용 약관 보이기 상태를 
     * 설정 합니다. 
     * @param {*} visible 
     */
    setServicePolicyVisible (visible){
        this.servicePolicyVisible = visible;
    }

    /**
     * 기 등록된 아이디 존재 여부를 
     * 요청 합니다. 
     * @param {*} idStr : 아이디
     */
    async doCheckIdExist (idStr) {
        try {
            let param = {
                [EzFormConst.EZ_COMPANY_ID]: idStr
            }
            let json = await EzHttpUtils.doSendPostRequest(EzURLConst.MEMBER_ID_SEARCH_URL, param);
            return json;
        }
        catch (e) {
            throw e;
        }
    }

    /**
     * 회원 가입을 요청 합니다. 
     * @param {*} idStr : 아이디 
     * @param {*} pwStr : 비밀번호 
     * @param {*} compName : 회사명
     * @param {*} ceoName : 대표자명 
     * @param {*} agePolicy : 만 14세 이상 확인
     * @param {*} privatePolicy : 개인정보 동의 
     * @param {*} servicePolicy : 서비스 이용 동의 
     * @param {*} marketingPolicy : 마케팅 동의
     */
    async doRegJoin (
        idStr,                  
        pwStr,                  
        compName,               
        ceoName,                
        agePolicy,               
        privatePolicy,          
        servicePolicy,          
        marketingPolicy           
    ) {
        let result = "";
        try {
            let param = {
                [EzFormConst.EZ_COMPANY_ID]: idStr,
                [EzFormConst.USER_PW]: pwStr,
                [EzFormConst.USER_COMP_NAME]: compName,
                [EzFormConst.USER_CEO_NAME]: ceoName,
                [EzFormConst.USER_POLICY_AGE_LIMIT_ACCEPT]: agePolicy,
                [EzFormConst.USER_POLICY_PRIVATE_INFO_ACCEPT]: privatePolicy,
                [EzFormConst.USER_POLICY_SERVICE_ACCEPT]: servicePolicy,
                [EzFormConst.USER_POLICY_MARKETING_ACCEPT]: marketingPolicy,
            }
            result = await EzHttpUtils.doSendPostRequest(EzURLConst.MEMBER_INSERT_DATA_URL, param);

            if(result.errorMsg != "") 
                throw new Error(result.errorMsg);
        }
        catch (e) {
            throw e;
        }
        return result;
    }
}