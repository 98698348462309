import { makeAutoObservable } from "mobx"
import * as EzHttpUtils from 'common/EzHttpUtils';
import * as EzURLConst from 'common/EzURLConst';
import * as EzFormConst from 'common/EzFormConst';

/**
 * Project : ezweb 
 * 
 * 로그인 관련 스토어 입니다. 
 * 
 * create by choi young ho. 
 * 2020.11.17
 */
export default class LoginStore {

    /**
     * Creates an instance of LoginStore.
     * root Store 객체를 지정 합니다. 
     * `    
     * @param {*} root
     * @memberof LoginStore
     */
    constructor(root) {
        this.root = root;
        makeAutoObservable(this);
    }

    /**
     * 기 등록된 아이디 존재 여부를 
     * 요청 합니다. 
     * @param {*} idStr : 아이디
     */
     async doLogin (idStr, pwStr) {
        try {
            let param = {
                [EzFormConst.EZ_COMPANY_ID]: idStr,
                [EzFormConst.USER_PW]: pwStr,
            }
            console.log("Param", param);
            let json = await EzHttpUtils.doSendPostRequest(
                EzURLConst.LOGIN_REQ_JSON, param);
            return json;
        }
        catch (e) {
            throw e;
        }
    }
}