import React, {useState} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import {CircularProgress} from '@mui/material';
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import illustration from "images/login-illustration.svg";
import logo from "images/logo.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import {  observer } from "mobx-react-lite";
import EzURLConst from "common/EzURLConst";

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const DividerTextContainer = tw.div`py-6 px-10 my-8 border flex-1`;
const DividerText = tw.div`px-2 text-sm text-gray-600 tracking-wide font-medium bg-white`;

const Form = tw.form`flex mx-auto max-w-xs items-center justify-center`;
const SubmitButton = styled.button`
  ${tw`mt-0 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;


const DemoLogin = observer(({
  logoLinkUrl = "/",
  illustrationImageSrc = illustration,
  headingText = "이지로드 체험하기",
  submitButtonText = "체험 시작",
  SubmitButtonIcon = LoginIcon,
  demoLoginId = 'ezmak@naver.com',
  demoLoginPw = '!ez1231300',
  loginStore,
}) => {
  console.log("Loing Store", loginStore)

  const [isLoading, setLoading] = useState('');

  const doSubmit = async (e) => {
    e.preventDefault();

    console.log("Try to Start Demo ");
    try {
      let result = await loginStore.doLogin(
        demoLoginId,
        demoLoginPw,
      );
      console.log("Result", result);
      if(result['errorMsg'] != "") {
        alert(result['errorMsg'])
      }
      else {
        alert("안녕하세요 이지로드 체험을 시작 합니다. ");
        window.location=EzURLConst.EZ_MAIN_URL;
      }
    }
    catch(e) {
      console.log(e);
    }
    setLoading(false);
  }

  return (
  <AnimationRevealPage>
    <Container>
      <Content>
        <MainContainer>
          <LogoLink href={logoLinkUrl}>
            <LogoImage src={logo} />
          </LogoLink>
          <MainContent>
            <Heading>{headingText}</Heading>
            <DividerTextContainer>
            <DividerText >데모 아이디({demoLoginId})로</DividerText>
            <DividerText>이지로드의 다양한 기능을</DividerText>
            <DividerText>체험 할 수 있습니다</DividerText>
            </DividerTextContainer>
            
            <FormContainer>
              <Form>
                {isLoading 
                ? 
                <CircularProgress />
                : 
                <SubmitButton onClick={doSubmit} state={isLoading}>
                  <SubmitButtonIcon className="icon" />
                  <span className="text">{submitButtonText}</span>
                </SubmitButton>
                }
              </Form>
            </FormContainer>
          </MainContent>
        </MainContainer>
        <IllustrationContainer>
          <IllustrationImage imageSrc={illustrationImageSrc} />
        </IllustrationContainer>
      </Content>
    </Container>
  </AnimationRevealPage>
  );
});


export default DemoLogin;