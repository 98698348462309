import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
//import Header from "components/headers/light.js";
import EzHeader from 'pages/common/ez_header';
import EzPricing from "pages/common/ez_price";

import EzFooter from 'pages/common/ez_footer';
import EzFaq from 'pages/common/ez_faq';

export default () => {
  return (
    <AnimationRevealPage>
      <EzHeader />
      <EzPricing/>
      <EzFaq/>
      <EzFooter/>
    </AnimationRevealPage>
  );
};
