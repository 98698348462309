/**
 * 이지로드는 누구에게 
 * 필요할까요 영역 
 */
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import SupportIconImage from "images/icons/counsel.png";
import OnAndOffImage from "images/icons/on_and_off.png";
import MoneyImage from 'images/icons/money.png';
import GroomyFace from 'images/icons/groomy_face.png'
import BarCodeImage from "images/icons/on_and_off.png";
import EzUsageImage from "images/icons/ez_icon.png";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`text-center flex-shrink-0`}
    img {
      ${tw`w-20 h-20`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, heading = "Amazing Features", subheading = "", description = "" }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    { imageSrc: OnAndOffImage, 
      title: "오프라인 온라인 통합", 
      description: "매장 관리 프로그램 쇼핑몰 관리 프로그램 따로 쓰지 마세요, 이지로드 하나면 됩니다"
    }, 
    {
      imageSrc: GroomyFace,
      title: "오배송이 싫으신 분",
      description: "수작업으로 작성하는 주문서 작성. 이지로드의 자동화 매핑 시스템은 주문서를 실수없이 클릭만으로 만들어 드립니다"
    },
    { 
      imageSrc: SupportIconImage, 
      title: "고객주문 통합 관리",
      description: "여기저기 로그인해야 확인 할 수 있는 고객의 주문내역.  고객주문 통합관리로 한번에 쉽게 관리하세요"
    },
    { imageSrc: MoneyImage, 
      title: "합리적 가격", 
      description: "전산 도입이 필요하지만 비용 때문에 망설여 지시나요? 월 만원이면 됩니다"
    },
    { imageSrc: BarCodeImage, 
      title: "바코드기반 재고관리", 
      description: "적확한 재고 파악의 기본, 바코드. 별도 장비 없이 핸드폰으로 실시간으로 관리하세요"
    },
    { imageSrc: EzUsageImage, 
      title: "쉬운 사용법", 
      description: "엑셀 처럼 쉬운 사용법 교육 받지 않아도 누구나 사용하실 수 있습니다"
    }, 
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
