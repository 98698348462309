
import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header, { NavLink, NavLinks, PrimaryLink } from "components/headers/light";
import EzURLConst from "common/EzURLConst";

export default ({ roundedHeaderButton = false }) => {
    const links = [
        <NavLinks key={1}>
            <NavLink href="/aboutUs">회사소개</NavLink>
            <NavLink href="/major_feature">주요기능</NavLink>
            <NavLink href="/pricing">제품가격</NavLink>
            <NavLink href="/contact_us">고객센터</NavLink>
            <NavLink href="/sign_up">회원가입</NavLink>
            <PrimaryLink css={roundedHeaderButton && tw`rounded-full`} href={EzURLConst.EZ_LOGIN_URL}>이지로드 시작하기</PrimaryLink>
            
        </NavLinks>
    ];

    return (
        <Header
            links={links}
        />
    );
}