import React, { useState, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import illustration from "images/large_images/join_us_image.png";
import {  observer } from "mobx-react-lite";
import { Beetle as Button } from 'react-button-loaders';
import { useAlert } from "react-alert";
import * as EzFormCheck from 'lib/EzFormCheck';
import EzConst from "common/EzConst";
import PrivatePolicyDialog from 'pages/member/PrivatePolicyDialog';
import ServicePolicyDialog from 'pages/member/ServicePolicyDialog';
import EzURLConst from "common/EzURLConst";

const Container = tw(ContainerBase)`min-h-screen bg-primary-500 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-1 first:mt-1`;

const ErrMsg = tw.div`text-red-500 text-sm `;

const SubmitButton = tw(Button)` mt-8`

const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
`;

const CheckboxArea = styled.div `
  display: flex;
  flex-direction:column;
  font-size : 0.875em;
  color:rgba(113,128,150,var(--text-opacity));
  font-weight:500;
`
const CheckBoxInput = styled.input `
vertical-align: -1px;
`;


/**
 * 회원 가입 화면 입니다. 
 * 
 * created by choi young ho. 
 * 2020.11.16
 */
const SingUp = observer( ({signUpStore}) => {
  const alert = useAlert();

  const [isLoading, setLoading] = useState('');

  const [emailValue, setEmail] = useState('');
  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const [pwValue, setPwValue] = useState('');
  const [pwErrorMsg, setPwErrorMsg] = useState('');
  const [pwConfirmValue, setPwConfirmValue] = useState('');
  const [pwConfirmErrorMsg, setPwConfirmErrorMsg] = useState('');
  const [compNameValue, setCompNameValue] = useState('');
  const [compNameErrorMsg, setCompNameErrorMsg] = useState('');
  const [ceoNameValue, setCeoNameValue] = useState('');
  const [ceoNameErrorMsg, setCeoNameErrorMsg] = useState('');

  const [allCheckState, setAllCheckState] = useState(false);
  const [ageCheckState, setAgeCheckState] = useState(false);
  const [privateInfoCheckState, setPrivateInfoState] = useState(false);
  const [serviceCheckState, setServiceCheckState] = useState(false);
  const [marketingCheckState, setMarketingCheckState] = useState(false);

  /**
   * 아이디 중복 여부를 
   * 체크 합니다. 
   */
  const doCheckId = () => {
    signUpStore.doCheckIdExist(emailValue)
      .then((result) => { 
        if(result.obj != null) 
          setEmailErrorMsg("이미 등록된 아이디 입니다 ");
        else 
          setEmailErrorMsg("");
      })
      .catch((e) => { console.log(e) });
  }

  /**
     * 비밀번호를 비교 합니다. 
     * @param {*} text 
     */
  const doCheckPwConfirm = (text)=>{

    let result = EzFormCheck.doCheckPwNotNull(
      text,
      setPwConfirmValue,
      "[영문,숫자,특수기호] 8글자 이상 비밀번호를 입력하세요",
      setPwConfirmErrorMsg);

    if (result && pwValue != text) {
      setPwConfirmErrorMsg('비밀번호가 일치 하지 않습니다. ');
      result = false;
    }
    return result;
  }

  /**
   * 이용 약관 모두 동의 
   * 상태를 변환 합니다
   */
  const toggleAllAcceptState = () => {
    let state = !allCheckState;
    setAllCheckState(state);
    setAgeCheckState(state);
    setPrivateInfoState(state);
    setServiceCheckState(state);
    setMarketingCheckState(state);
  }

  useEffect(() => {
    let allState =  false;
    if(ageCheckState && 
      privateInfoCheckState && serviceCheckState && marketingCheckState)
      allState = true;

    setAllCheckState(allState);
  }, [ageCheckState, privateInfoCheckState, serviceCheckState,marketingCheckState]);


  /**
   * 사용자의 입력 값을 체크 하고 
   * 사용자 등록을 요청 합니다. 
   */
  const doSubmit = async (e) => {
    e.preventDefault();

    // 아이디가 중복된 경우 제외 합니다. 
    if(emailErrorMsg != "") {
      alert.show("아이디를 확인 하세요.",{
        title: "알림",
        closeCopy: "닫기",
      });
    }

    if(!EzFormCheck.doCheckEmailNotNull(
        emailValue, 
        setEmail, 
        "이메일 주소를 입력하세요.", 
        setEmailErrorMsg)) return;    
    
    if(!EzFormCheck.doCheckPwNotNull(
      pwValue,
      setPwValue,
      "[영문,숫자,특수기호] 8글자 이상 비밀번호를 입력하세요",
      setPwErrorMsg)) return;    

    if (!doCheckPwConfirm(pwConfirmValue)) return;
    
    if(!EzFormCheck.doCheckStrNotNull(
      compNameValue,
      setCompNameValue,
      "회사명을 입력하세요",
      setCompNameErrorMsg)) return;    

    if(!EzFormCheck.doCheckStrNotNull(
      ceoNameValue,
      setCeoNameValue,
      "대표자명을 입력하세요",
      setCeoNameErrorMsg)) return;    

    if(!ageCheckState || !privateInfoCheckState || !serviceCheckState) {
      alert.show("이용 약관을 동의해 주세요" ,{
        title: "알림",
        closeCopy: "닫기",
      });
      return;
    }

    try {
      // 회원 가입을 요청 합니다. 
      setLoading('loading');
      let result = await signUpStore.doRegJoin(
        emailValue,
        pwValue,
        compNameValue,
        ceoNameValue,
        ageCheckState? EzConst.USER_POLICY_STATE_ACCEPT : EzConst.USER_POLICY_STATE_DENY,
        privateInfoCheckState? EzConst.USER_POLICY_STATE_ACCEPT : EzConst.USER_POLICY_STATE_DENY,
        serviceCheckState? EzConst.USER_POLICY_STATE_ACCEPT : EzConst.USER_POLICY_STATE_DENY,
        marketingCheckState? EzConst.USER_POLICY_STATE_ACCEPT : EzConst.USER_POLICY_STATE_DENY,
      );
      setLoading('finished');

      alert.show("회원가입을 감사 드립니다. 로그인페이지로 이동합니다" ,{
        title: "알림",
        closeCopy: "확인",
        type: 'success',
        onClose: () => {
          window.location=EzURLConst.EZ_LOGIN_URL;
          //history.push("/aboutUs");
        }
      });
    }
    catch(e) {
      console.log(e);
      //toast(e.toString(), {position: "top-center",});
      alert.error(e.toString(),{
        title: "알림",
        closeCopy: "닫기",
      });
      setLoading('');
    }
    return false;
  }


  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={"/"}>
              <LogoImage src="/images/logo.png" />
            </LogoLink>
            <MainContent>
              <Heading>이지로드 회원가입</Heading>
              
              <FormContainer>
                <DividerTextContainer>
                  <DividerText>가입 신청 정보</DividerText>
                </DividerTextContainer>
                <Form>
                  <p tw="text-xs text-gray-600 text-left">
                    이메일를 아이디로 사용 합니다
                  </p>
                  <Input type="email" placeholder="아이디(이메일)를 입력하세요" 
                    value={emailValue}
                    onChange={(e) => {
                      let txt = e.target.value;
                      EzFormCheck.doCheckEmailNotNull(txt, setEmail, "이메일 주소를 입력하세요.", setEmailErrorMsg);
                    }}
                    onBlur={(e) => {
                      // 회원 아이디 중복 여부를 체크 합니다. 
                      if (emailValue != "" && emailErrorMsg == "") doCheckId();
                    }}
                  />
                  {
                    emailErrorMsg != "" &&  <ErrMsg> {emailErrorMsg} </ErrMsg>
                  }
                  <Input type="password"   
                    value={pwValue}
                    placeholder="[영문,숫자,특수기호] 8글자 이상 비밀번호를 입력하세요"
                    onChange={(e) => {
                      let txt = e.target.value;
                      EzFormCheck.doCheckPwNotNull(
                        txt,
                        setPwValue,
                        "[영문,숫자,특수기호] 8글자 이상 비밀번호를 입력하세요",
                        setPwErrorMsg
                      );
                    }}
                  />
                  {
                    pwErrorMsg != "" && <ErrMsg>{pwErrorMsg}</ErrMsg>
                  }
                  <Input type="password" 
                    value={pwConfirmValue}
                    placeholder="[영문,숫자,특수기호] 8글자 이상 비밀번호를 입력하세요"
                    onChange={(e) => {
                      doCheckPwConfirm(e.target.value);
                    }}
                  />
                  {
                    pwConfirmErrorMsg != "" && <ErrMsg>{pwConfirmErrorMsg}</ErrMsg>
                  }
                  <DividerTextContainer>
                    <DividerText>회사 정보</DividerText>
                  </DividerTextContainer>
                  <Input type="회사명" 
                    value={compNameValue}
                    placeholder="회사명을 입력하세요" 
                    onChange={(e) => {
                      EzFormCheck.doCheckStrNotNull(
                        e.target.value,
                        setCompNameValue,
                        "회사명을 입력하세요",
                        setCompNameErrorMsg
                      )
                    }}
                  />
                  {
                    compNameErrorMsg != "" && <ErrMsg>{compNameErrorMsg}</ErrMsg>
                  }
                  <Input type="대표자명" 
                    value={ceoNameValue}
                    placeholder="대표자명을 입력하세요" 
                    onChange={(e) => {
                      EzFormCheck.doCheckStrNotNull(
                        e.target.value,
                        setCeoNameValue,
                        "대표자명을 입력하세요",
                        setCeoNameErrorMsg
                      )
                    }}
                  />
                  {
                    ceoNameErrorMsg != "" && <ErrMsg>{ceoNameErrorMsg}</ErrMsg>
                  }
                  <DividerTextContainer>
                    <DividerText>회원 가입 약관 동의</DividerText>
                  </DividerTextContainer>
                  <CheckboxArea>
                    <div style={{flex:1, flexDirection:'rows'}}>
                      <CheckBoxInput type="checkbox" checked={allCheckState} onChange={toggleAllAcceptState}/> 모두 동의 합니다 <br/><br/>
                    </div>
                    <div style={{display:'flex', flex:1, flexDirection:'row', justifyContent:'space-between'}}>
                      <div>
                        <CheckBoxInput type="checkbox" checked={ageCheckState} onChange={()=>{setAgeCheckState(!ageCheckState);}} /> 만 14세 이상입니다 (필수) 
                      </div>
                    </div>
                    <div style={{display:'flex', flex:1, flexDirection:'row', justifyContent:'space-between'}}>
                      <div>
                        <CheckBoxInput type="checkbox" checked={privateInfoCheckState}  onChange={()=>{setPrivateInfoState(!privateInfoCheckState)}} /> 개인정보 처리 방침 (필수)<br/>
                      </div>
                      <a href='#' style={ {justifyItems:'flex-end'}} onClick={()=>{signUpStore.setPolicyVisible(true)}} >보기</a>
                      
                    </div>
                    <div style={{display:'flex', flex:1, flexDirection:'row', justifyContent:'space-between'}}>
                      <div>
                        <CheckBoxInput type="checkbox" checked={serviceCheckState}  onChange={()=>{setServiceCheckState(!serviceCheckState)}} /> 서비스 이용 약관 (필수)<br/>
                      </div>
                      <a href='#' style={ {justifyItems:'flex-end'}} onClick={()=>{signUpStore.setServicePolicyVisible(true)}} >보기</a>
                    </div>
                    <div style={{display:'flex', flex:1, flexDirection:'row', justifyContent:'space-between'}}>
                      <div>
                        <CheckBoxInput type="checkbox" checked={marketingCheckState} onChange={()=>{ setMarketingCheckState(!marketingCheckState)}}/> 이벤트 및 할인 혜택 안내 동의 (선택)
                      </div>
                    </div>
                  </CheckboxArea>
                  <SubmitButton onClick={doSubmit} state={isLoading}>
                    <span className="text">회원가입</span>
                  </SubmitButton>
                </Form>
              </FormContainer>
              <PrivatePolicyDialog signUpStore={signUpStore}/>
              <ServicePolicyDialog signUpStore={signUpStore}/>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustration} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  )
});

export default SingUp;
/*
export default inject(({ signUpStore }) => ({
  doCheckIdExist: signUpStore.doCheckIdExist,
  policyVisible : signUpStore.policyVisible, 
  setPolicyVisible : signUpStore.setPolicyVisible, 
}))(observer(SingUp));
*/

