
import React from "react";
import MajorFeature from "components/features/MajorUseCase.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line

import orderIconImage from "images/icons/order.png";
import itemLinkIconImage from "images/icons/item_mapping.png";
import crmIconImage from "images/icons/crm.png"
import mobileStockIconImage from "images/icons/mobile_stock.png";
import itemIconImage from "images/icons/item.png";
import partnerIconImage from "images/icons/multi_price.png";
import invoiceIconImage from "images/icons/invoice.png";
import estimateIconImage from "images/icons/estimate.png";
import writePenIconImage from "images/icons/write_pen.png";

export default () => {
    const HighlightedText = tw.span`text-primary-500`;
    return (
        <MajorFeature
        heading={
            <>
              이지로드 <HighlightedText>주요 기능</HighlightedText>
            </>
        }
        cards = {[
          { imageSrc: orderIconImage, 
            title: "쇼핑몰 주문 관리", 
            description: "Cafe24 마켓통합관리 연동 간단한 설정으로 국내 주요 쇼핑몰 주문 통합 관리"
          }, 
          {
            imageSrc: itemLinkIconImage,
            title: "주문상품 자동 매핑",
            description: "마켓별 주문 상품 자동 매핑 기능으로 자동화면 주문서 작성 시스템"
          },
          { 
            imageSrc: crmIconImage, 
            title: "고객문의 통합관리",
            description: "마켓 마다 로그인해야 하는 번거로움 없이 고객문의을 한 화면에서 통합 관리"
          },
          { imageSrc: mobileStockIconImage, 
            title: "모바일 재고관리", 
            description: "모바일 앱으로 언제 어디서나 재고 확인 및 실사 지원 쉽고 빠른 재고 관리"
          }, 
          { imageSrc: itemIconImage, 
            title: "상품관리", 
            description: "상품별 해쉬태그를 통합 신속한 상품 검색, 상품 이미지 등록을 지원하여 정확한 상품 관리"
          },
          { imageSrc: partnerIconImage, 
            title: "거래처 다중단가 관리", 
            description: "거래처별로 다중 단가 지원. 메모 기능을 통하여 업체별 특이 사항 관리"
          },
          { imageSrc: invoiceIconImage, 
            title: "매입/매출 관리", 
            description: "엑셀처럼 쉬운 전표 등록, 상품명, 규격, 해쉬테그를 통합 검색을 통하여 업무 효율의 극대화"
          }, 
          { imageSrc: writePenIconImage , 
            title: "견적서", 
            description: "사무실에서도 모바일에서도 견적서 등록/조회 가능하며, 어디서나 견적서를 등록하고 고객에게 PDF로 공유 기능 제공"
          }, 
          { imageSrc: estimateIconImage, 
            title: "경비관리", 
            description: "매출 전표 입출금 금액을 현금 시재에 실시간으로 반영하여 정확한 현금 시재 관리"
          }, 
        ]}
      />
    );
}
