import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import EzHeader from "pages/common/ez_header";
import EzFooter from "pages/common/ez_footer";
import Record from "components/features/TwoColWithButton.js";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <EzHeader />
      <Record
        subheading={<Subheading>이지로드 이야기</Subheading>}
        heading="이지로드를 개발하기 까지"
        //buttonRounded={false}
        //primaryButtonText="See Portfolio"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        description={"전산 업계에 10여년간 종사하다 부모님이 운영하시던 작은 매장을 운영 하게 되었습니다.연 매출 10억원 정도의 작은 매장 이었조. \n" +
                      "운영 수업을 받으며 느꼈던 것은\n" + 
                      "매일 반복되는 업무로 인해 의외로 업무량이 많은데  도움을 받을 수 있는 프로그램을 찾기가 어렵더군요.\n" + 
                      "처음에는 시중에 있는 유통 관리 프로그램을 구매 하여 사용하였습니다 \n" +
                      "쓰면서 쉽고 편한 프로그램이 하나 있어야 겠다는 생각이 들게 되었습니다 \n\n" +
                      "누구나 바로 쓸수 있는 작고 쉬운 프로그램, 이지로드의 시작 이었습니다. \n"
                    }
      />
      <Record
        subheading={<Subheading>Our Vision</Subheading>}
        heading="비싸고 어려운 것 보다는 저렴하고 쉬운 것을 만들겠습니다"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
        description={
          `프로그램 개발에는 많은 비용이 소요 됩니다.
          최소한의 인력으로 꼭 필요한 기능만을 구현한다면 당연히 비용을 줄일 수 있겠죠. 
          매장 운영에도 많은 비용이 들어 갑니다. 
          안그래도 들어가는 돈이 많은데 매장 관리 프로그램이 비싸면 안되겠죠 
          모든 고객을 다 만족 시킬수는 없겠지만, 
          보다 더 많은 사람들이 큰 비용 들이지 않고
          매장을 손쉽게 운영할 수 있는 매장 관리의 쉬운 길을 찾아가겠습니다.`
        }
      />
      <Record
        subheading={<Subheading>재고 관리 앱 개발</Subheading>}
        heading="재고관리를 보다 쉽고 정확하게 할 수 있도록"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        description={
          `매장을 운영하면서 느꼈던 가장 큰 어려움은 재고관리였습니다.
          잘 나가는 제품은 자꾸 재고가 떨어 지고, 잘 안나가는 제품은 자리를 굳건히 차지 하고 있고...
          몇 안되는 직원 이었지만 담당 직원이 자리를 비우면, 있는 제품도 못 찾아서 판매를 못하는 일도 허다했습니다.
          대학 교수님이 진행하는재고 관리 수업도 들어 봤지만 별 도움이 되지 않더군요.
          재고 관리가 잘 안되는 이유는 뭘까요? 저희가 내린 결론은 시간 이었습니다.
          재고 실사를 조사하는 시간과 그 실사 결과를 전산에 반영하는 시간의 간격 !
          실제 재고 조사를 한 후 전산에 등록하기 까지, 보통 서너 시간의 간격이 있죠
          문제는 그 짧은 시간 사이에도 상품의 입출고가 계속 이루어 진다는 것이죠
          나아가 재고 실사라는 행위도 큰 맘 먹고 해야 하는 일이라, 잘 안하게 되죠.
          만약에 물품을 출고하는 직원이 출고 시에 재고를 확인하고 수정 할 수 있다면
          시간의 간격과 인력의 문제를 모두 해결 할 수 있지 않을까요?
          그래서  모바일 재고 관리 앱을 만들었습니다. `
        }
      />
      {/*
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
        ]}
        linkText=""
      />
      */}
      {/*
      <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      />
      */}
      <EzFooter />
    </AnimationRevealPage>
  );
};
