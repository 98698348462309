let EZ_SERVER_HOST =  ( "production" == process.env.NODE_ENV ) 
                        ? "https://dev.ddimsoft.co.kr:8443/ezroad_v5" 
                        : "http://localhost:8080/ezroad_v5" ;          // Node Server Root 

module.exports = Object.freeze({
    EZ_LOGIN_URL            : `${EZ_SERVER_HOST}/login`,
    EZ_MAIN_URL             : `${EZ_SERVER_HOST}/main`,
    LOGIN_REQ_JSON			: `${EZ_SERVER_HOST}/login_req_data.json`,				// 사용자 인증 요청
    MEMBER_ID_SEARCH_URL	: `${EZ_SERVER_HOST}/member_id_search_data.json`,       // 기존에 등록된 아이디 정보 요청
    MEMBER_INSERT_DATA_URL	: `${EZ_SERVER_HOST}/member_insert_data.json`,			// 회원 등록 요청 URL Data
    CUSTOMER_CONTACT_US_URL	: `${EZ_SERVER_HOST}/customer/contact_us_admin.json`,	// 고객 문의 접수 URL
});