/**
 * 문자열을 제거 하여 숫자로 반환 합니다. 
 *
 * @param targetUrl : URL 
 */
export const doSendPostRequest = async ( targetUrl, paramMap, authStore )=>{
    let json = [];
    try {
        var formBody = [];
        for (var property in paramMap) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(paramMap[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        let options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
            credentials: 'include',         // added for cors cookie.
            body: formBody
        };
        
        let response = await fetch(targetUrl, options);
        json = await response.json();
    }
    catch(err) {
        console.log("[EzHttpUtils] Err", err);
        throw err;
    }
    return json;
 }