import React from "react";
import {  observer } from "mobx-react-lite";
import styled from "styled-components";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import tw from "twin.macro";
import { ReactComponent as  CloseIcon } from "feather-icons/dist/icons/x.svg";

const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const PrivatePolicyDialog = observer(  ({signUpStore}) => {
  const closeModal = () => {
    signUpStore.setPolicyVisible(false);
  }

  const contentStyle = {
    maxWidth: '600px',
    width: '90%',
    overflow:'scroll',
    height:'80%'
  };

  return (
    <Popup open={signUpStore.policyVisible} closeOnDocumentClick onClose={closeModal} contentStyle={contentStyle}>
        <div className="modal">
          <SubmitButton onClick={closeModal}>
            <CloseIcon className="icon" />
            <span className="text">닫기</span>
          </SubmitButton>
          <br/>
<p>이지로드는 (이하 &#39;회사&#39;는) 고객님의 개인정보를 중요시하며, &quot;정보통신망 이용촉진 및 정보보호&quot;에 관한 법률을 준수하고 있습니다.&nbsp;<br />
회사는 개인정보취급방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.<br />
제1조 수집하는 개인정보 항목<br />
가. 수집하는 항목<br />
회사는 회원가입, 상담, 서비스 신청 등등을 위해 아래와 같은 개인정보를 수집하고 있습니다.<br />
이름, 로그인ID, 비밀번호, 휴대전화번호, 이메일, 직업, 회사명, 회사전화번호, 서비스이용기록, 접속 로그, 결제기록<br />
나. 개인정보 수집방법<br />
홈페이지 (www.ezroad.co.kr)<br />
제2조 개인정보의 수집 및 이용목적<br />
가. 이용목적<br />
회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.<br />
서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산, 구매 및 요금결제, 물품배송 또는 청구서 등 발송<br />
나. 회원 관리<br />
회원제 서비스 이용에 따른 본인확인, 불만처리 등 민원처리<br />
다. 신규 서비스 개발 및 마케팅*광고에 활용<br />
플레이오토 가 제공하는 신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 정보 및 참여기회 제공, 광고성 정보 제공, 접속빈도 파악, 실사업자 확인, 회원의 서비스이용에 대한 통계 를 통한 효율적 관리에 사용한다.<br />
제3조. 개인정보의 보유 및 이용기간<br />
가. 개인정보의 보유<br />
원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.<br />
나. 보존기간<br />
보존항목 : 이름, 로그인ID, 비밀번호, 휴대전화번호, 이메일, 회사명, 회사전화번호, 결제기록<br />
보존근거 : 서비스 재사용대비 기록관리<br />
보존기간 : 6개월<br />
보존항목 : 서비스 이용기록, 접속 로그<br />
보존근거 : 서비스 재사용대비 기록관리<br />
보존기간 : 1개월<br />
4. 동의거부권 및 거부시 불이익<br />
고객은 개인정보 수집/이용에 대하여 거부할 수 있는 권리가 있습니다. 단, 이에 대한 동의를 거부할 경우에는 서비스이용이 불가합니다.</p>

      </div>
    </Popup>
  );
});

export default PrivatePolicyDialog;
