import validate from 'validate.js';
import * as StringUtils from 'lib/StringUtils';

/**
 * 체크 결과를 처리 합니다. 
 * 
 * @param {*} text              : 문자열
 * @param {*} validateResult    : 체크 결과 
 * @param {*} setupFunc         : 문자열의 값을 할당하는 CallBack Func
 * @param {*} errMsg            : 에러 메세지 
 * @param {*} errMsgFunc        : 에러 메지지 설정 CallBack Func
 * @param {*} errFunc           : 에러 여부 Status 설정 CallBack Func
 */
const ezFormCheckResult = (text, validateResult, setupFunc, errMsg = '', errMsgFunc = null, errStateFunc = null) => {
    let result = true;

    if (validateResult == undefined || validateResult == "") {
        if (errStateFunc != null) errStateFunc(true);
        if (errMsgFunc != null) errMsgFunc('');
        result = true;
    }
    else {
        if (errStateFunc != null) errStateFunc(false);
        if (errMsgFunc != null) errMsgFunc('');
        errMsgFunc(errMsg);
        result = false;
    }
    if (setupFunc != null) setupFunc(text);
    return result;
}

/**
 * 이메일 주소를 체크 합니다. 
 * 
 * @param {*} text              : 문자열
 * @param {*} setupFunc         : 문자열의 값을 할당하는 CallBack Func
 * @param {*} errMsg            : 에러 메세지 
 * @param {*} errMsgFunc        : 에러 메지지 설정 CallBack Func
 * @param {*} errFunc           : 에러 여부 Status 설정 CallBack Func
 */
export const doCheckEmailNotNull = (text, setupFunc, errMsg = '', errMsgFunc = null, errStateFunc = null) => {
    let result = true;
    let validateResult = validate.single(text , {presence: { allowEmpty: false }, email: true});
    if (validateResult == undefined || validateResult == "") {
        if (errStateFunc != null) errStateFunc(true);
        if (errMsgFunc != null) errMsgFunc('');
        result = true;
    }
    else {
        if (errStateFunc != null) errStateFunc(false);
        if (errMsgFunc != null) errMsgFunc('');
        errMsgFunc(errMsg);
        result = false;
    }

    if (setupFunc != null) setupFunc(text);
    return result;
}

/**
 * 필수 비밀번호를 체크 합니다. 
 * @param {*} text              : 문자열
 * @param {*} setupFunc         : 문자열의 값을 할당하는 CallBack Func
 * @param {*} errMsg            : 에러 메세지 
 * @param {*} errMsgFunc        : 에러 메지지 설정 CallBack Func
 * @param {*} errFunc           : 에러 여부 Status 설정 CallBack Func
 */
export const doCheckPwNotNull = (text, setupFunc, errMsg = '', errMsgFunc = null, errStateFunc = null) => {
    let result = true;
    
    let validateResult = validate.single( text , {
        presence: true, 
        //format: /^.*(?=^.{8,15}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/
        format: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/
    });

    if (validateResult == undefined || validateResult == "") {
        if (errStateFunc != null) errStateFunc(true);
        if (errMsgFunc != null) errMsgFunc('');
        result = true;
    }
    else {
        if (errStateFunc != null) errStateFunc(false);
        if (errMsgFunc != null) errMsgFunc(errMsg);
        result = false;
    }
    if (setupFunc != null) setupFunc(text);
    return result;
}

/**
 * 필수 문자열을 체크 합니다. 
 * 
 * @param {*} text              : 문자열
 * @param {*} setupFunc         : 문자열의 값을 할당하는 CallBack Func
 * @param {*} errMsg            : 에러 메세지 
 * @param {*} errMsgFunc        : 에러 메지지 설정 CallBack Func
 * @param {*} errFunc           : 에러 여부 Status 설정 CallBack Func
 */
export const doCheckStrNotNull = (text, setupFunc, errMsg = '', errMsgFunc = null, errStateFunc = null) => {
    let result = true;
    let msg = validate.single(text, { presence: { allowEmpty: false } });
    return ezFormCheckResult(text, msg, setupFunc, errMsg, errMsgFunc, errStateFunc);
}

/**
 * 필수 전화번호를 체크 합니다. 
 * 
 * @param {*} text              : 문자열
 * @param {*} setupFunc         : 문자열의 값을 할당하는 CallBack Func
 * @param {*} errMsg            : 에러 메세지 
 * @param {*} errMsgFunc        : 에러 메지지 설정 CallBack Func
 * @param {*} errFunc           : 에러 여부 Status 설정 CallBack Func
 */
export const doCheckPhoneNotNull = (text, setupFunc, errMsg = '', errMsgFunc = null, errStateFunc = null) => {

    // 전화번호 형식으로 변환 합니다. 
    text = StringUtils.toTelNoString(text);

    let msg = validate.single(text,
        {
            presence: { allowEmpty: false },
            format: /^\d{3}-\d{3,4}-\d{4}$/
        }
    );
    
    return ezFormCheckResult(text, msg, setupFunc, errMsg, errMsgFunc, errStateFunc);
}

/**
 * 원단위 표시 문자열을 
 * 체크 합니다. 
 * 
 * 콤마(,)를 제외한 모든 문자열을 
 * 예외 처리 하며 
 * 남은 숫자를 
 * 원단위 표시하여 값을 설정 합니다. 
 * 
 * 값의 존재 여부에 따라 결과값이 
 * 결정 됩니다. 
 * 
 * @param {*} text              : 원단위 포맷팅 문자열 
 * @param {*} setupFunc         : 문자열의 값을 할당하는 CallBack Func
 * @param {*} errMsg            : 에러 메세지 
 * @param {*} errMsgFunc        : 에러 메지지 설정 CallBack Func
 * @param {*} errFunc           : 에러 여부 Status 설정 CallBack Func
 */
export const doCheckWonNumberStrNotNull = (text, setupFunc, errMsg = '', errMsgFunc = null, errStateFunc = null) => {
    let result = "";            // blank is check OK State.
    let value = StringUtils.toNumber(text);
    let validateResult = StringUtils.toWonString(value);

    if(validateResult == "") {
        result = "No Blank.";
    }
    return ezFormCheckResult(validateResult, result, setupFunc, errMsg, errMsgFunc, errStateFunc);
}
