
import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line

import FAQ from "components/faqs/SingleCol.js";

export default () => {
    const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
    
    return (
        <FAQ
            subheading={<Subheading>FAQS</Subheading>}
            heading={
                <>
                    고객들이 자주 묻는 질문입니다
                </>
            }
            description={""}
            faqs={[
                {
                    question: "바코드 발행은 별도의 비용이 드나요 ?",
                    answer:
                        "바코드 재고 관리 시스템을 도입하는 데 필요한 비용은 없습니다. "
                },
            ]}
        />
    );
};