import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
//import Header from "components/headers/light.js";
import EzHeader from 'pages/common/ez_header';
import MajorSystemFeature from "components/features/MajorUseCase.js";
import EzMajorFeature from "pages/common/ez_major_feature";

import EzFooter from 'pages/common/ez_footer';
import EzFaq from 'pages/common/ez_faq';

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

export default () => {
  const HighlightedText = tw.span`text-primary-500`;


  
  return (
    <AnimationRevealPage>
      <EzHeader />
      <MajorSystemFeature
        heading={
            <>
              이지로드 <HighlightedText>시스템 특징</HighlightedText>
            </>
        }
        cards = {[
          { imageSrc: CustomizeIconImage, 
            title: "웹 기반 시스템", 
            description: "별도 설치 없이 어디서나 바로 사용할 수 있습니다 "
          }, 
          {
            imageSrc: ShieldIconImage,
            title: "클라우드 데이터 보관",
            description: "클라우드 기반의 고도화된 보안 시스템으로 데이터 분실 걱정없는 높은 신뢰도"
          },
          { 
            imageSrc: SupportIconImage, 
            title: "모바일 완벽 연동",
            description: "모바일 앱이 실시간으로 연동되어 이동형 오피스 완벽 지원"
          },
          { imageSrc: SimpleIconImage, 
            title: "사용자 무제한", 
            description: "운영자 시스템을 통해 자유롭게 사용자를 추가 가능. 추가 비용 제로"
          },
          { imageSrc: ReliableIconImage, 
            title: "무료 업그레이드", 
            description: "사용자의 요구 사항을 지속적으로 반영하는 무료 업그레이드"
          },
          { imageSrc: CustomizeIconImage, 
            title: "저렴한 가격", 
            description: "가입비 0원, 추가 비용 없이 월 만원에 이 모든 기능을"
          }, 
        ]}
      />
      <EzMajorFeature/>
      <EzFaq/>
      <EzFooter/>
    </AnimationRevealPage>
  );
};
