import React from "react";
import {  observer } from "mobx-react-lite";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as  CloseIcon } from "feather-icons/dist/icons/x.svg";

const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;


const ServicePolicyDialog = observer ( ({signUpStore}) => {
  
      const closeModal = () => {
            signUpStore.setServicePolicyVisible(false);
      }

      const contentStyle = {
            maxWidth: '600px',
            width: '90%',
            overflow:'scroll',
            height:'80%'
      };


  return (
      <Popup open={signUpStore.servicePolicyVisible} 
            closeOnDocumentClick onClose={closeModal} 
            contentStyle={contentStyle}>
        <div className="modal">
            <SubmitButton onClick={closeModal}>
                  <CloseIcon className="icon" />
                  <span className="text">닫기</span>
            </SubmitButton>
            <br/>
          <p>제1조 (목적)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
본 약관은 (주)리뉴하우스가(이하&rdquo;회사)가 제공하는 이지로드(www.ezroad.co.kr)서비스의 가입조건 및 이용에 관한 제반 사항과 기타 필요한 사항을 구체적으로 규정함을 목적으로 합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
제2조 (약관의 효력 및 적용과 변경)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;1. 약관의 효력 및 적용&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
이 약관은 사이트 게시판을 통하여 이용자에게 공지 함으로써 효력을 발생 합니다.<br />
이 약관에 동의 하고 회원가입을 한 회원은 약관에 동의한 시점부터 동의한 약관의 적용을 받고&nbsp;<br />
약관의 변경이 있을 경우에는 변경의 효력이 발생한 시점부터 변경된 약관의 적용을 받습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;2. 약관의 변경&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
회사는 필요하다고 인정되는 경우 관계법령에 위배되지 않는 범위에서 약관을 변경할 수 있으며&nbsp;&nbsp; &nbsp;&nbsp;<br />
약관이 변경된 경우에는 시행일자 및 개정사유를 명시하여 시행일로부터 7일 이전에 사전 공지 합니다.&nbsp;<br />
회원은 변경된 약관사항에 동의하지 않으면 서비스 이용을 중단하고 이용 계약을 해지 할 수 있으며&nbsp;&nbsp; &nbsp;<br />
변경된 약관은 공지 기재된 기일 또는 공지 후 7일 까지 거부의사를 표시하지 아니하고 서비스를 계속 사용할 경우&nbsp;&nbsp; &nbsp;&nbsp;<br />
약관의 변경 사항에 동의한 것으로 간주 됩니다&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
제3조 (약관 외 준칙)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
약관에 규정되지 않은 사항에 대해서는 전자문서 및 전자거래 기본법&nbsp;&nbsp; &nbsp;&nbsp;<br />
전자상거래 등에서의 소비자 보호에 관한 법률 등 관계법령 및 회사가 정한 서비스의 개별 이용약관&nbsp;&nbsp; &nbsp;&nbsp;<br />
세부이용지침 및 규칙 등의 규정을 따르게 됩니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
제4조 (용어의 정의)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
이 약관에서 사용하는 용어의 정의는 다음과 같습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;* 회원: 회사와 서비스 이용계약 체결한 자 또는 업체&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;* 결제자: 회사의 서비스 이용을 신청한 회원으로서 유료 결제를 신청하고 회사가 정한 결제 절차를 완료한 자를 말합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;* ID: 회원의 식별과 서비스 이용을 위하여 이용고객이 선정하고 회사가 승인하는 문자와 숫자의 조합을 말합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;* 비밀번호: 회원임을 확인하고 이용고객의 권익보호를 위하여 회원이 선정한 문자와 숫자의 조합을 말합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;* 탈퇴: 회사 또는 회원이 이용계약을 해약하는 것을 말합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;* 운영자: 서비스의 전반적인 관리와 운영을 담당하는 회사의 직원을 말합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;* 환불: 결제자는 회사가 지정한 환불 가능 기간 내에 유료 서비스이용을 철회한 경우 회사의 환불 정책에 따라 결제자에게 환불 가능한 금액을 지불한 수단으로 돌려주는 행위를 말합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;* 유료서비스이용료: 회사의 특화된 유료서비스를 이용하기 위하여 회원이 일정 금액을 지불 하는 행위를 말 합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;* 서비스 이용기간: 회사의 유료 서비스 이용이 가능한 회원별 서비스 사용기간을 말합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;</p>

      <p>제5조 (이용계약의 성립)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;1.&nbsp;&nbsp; &nbsp;이용계약은 이용고객이 본 이용약관 내용에 대한 동의와 이용신청에 대하여 회사의 이용승낙으로 성립합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;2.&nbsp;&nbsp; &nbsp;본 이용약관에 대한 동의는 이용신청 당시 이지로드 사이트의 &#39;약관동의&#39; 버튼을 누름으로써 의사표시를 합니다&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;3.&nbsp;&nbsp; &nbsp;회원은 회사의 이용료정책에 따라 이용료를 지속 지불 시 서비스 이용기간이 연장 진행됩니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;4.&nbsp;&nbsp; &nbsp;서비스 이용료는 회사 서비스 이용료 정책에 제시된 내용에 준합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
제6조 (서비스 이용 신청)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;1.&nbsp;&nbsp; &nbsp;회원으로 가입하여 본 서비스를 이용하고자 하는 이용고객은 회사에서 요청하는 제반 정보를 제공하여야 합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;2.&nbsp;&nbsp; &nbsp;타인의 명의를 도용하여 이용신청을 한 회원의 모든 ID는 삭제되며&nbsp;&nbsp; &nbsp; 관계법령에 따라 처벌을 받을 수 있습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;3.&nbsp;&nbsp; &nbsp;회사는 본 서비스를 이용하는 회원을 등급별로 구분하여 이용기간 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;4.&nbsp;&nbsp; &nbsp;약관에서 사용하는 용어의 정의는 제1 항에서 정하는 것을 제외하고는 관계법령 및 서비스별 안내에서 정하는 바에 의합니다</p>

      <p>제7조(모바일 기기에서의 서비스 사용)<br />
&nbsp;1. 서비스는 인터넷을 통해 사용하는 호환성 있는 모바일 기기에서 사용이 가능합니다. 고객은 고객의 모바일 기기 및 통신사의 약관 조항과 일반 사용법, 서비스의 업데이트 등 모든 변경 사항에 대해 스스로 책임을 집니다.<br />
&nbsp;2. 이지로드는 이지로드의 귀책 사유가 없는 서비스 장애 상황 등(다음 사항을 포함하되, 이에 한하지 않음)에 대해 어떠한 책임도 지지 않습니다.<br />
&nbsp; * &nbsp;고객 통신서비스 제공사의 서비스 제공 가능 여부<br />
&nbsp; * &nbsp;시간 및 장소를 불문한 서비스 접속 가능 여부<br />
&nbsp; * &nbsp;통신서비스 관련 서비스 일체의 누락, 손상 또는 보안 침해<br />
&nbsp; * &nbsp;데이터 정보 전송 실패 또는 서비스 연결 설정 실패<br />
&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
제8조(데이터 보호 및 관리)<br />
&nbsp;1. 데이터란 고객이 입력하여 물리적인 서버 내에 저장된 자료(기초자료, 입력전표, 게시글, 소프트웨어, 음악, 소리, 사진, 그래픽, 비디오, 메시지를 포함하되, 이에 한하지 않음)&nbsp;<br />
&nbsp; &nbsp; &nbsp;및 그 자료들의 결합, 변형 또는 재연산을 통해 생성되는 부가 자료 일체를 말하며, 고객이 제공, 노출하였거나 이지로드에 의해 파악된 고객의 자료(고객 자신, 서비스 사용 경험, 고객의 비즈니스, 기타)를 포함합니다.&nbsp;<br />
&nbsp; &nbsp; &nbsp;이하 본 약관에서 고객정보나 데이터 중 제 9조 1항에 정의한 개인정보는 대상에서 제외하며, 위탁된 개인정보는 개인정보처리위탁에 관한 본 약관 제3조를 준수합니다.<br />
&nbsp;2. 이지로드는 고객 데이터를 보호하며 고객 데이터에 대한 허락 받지 않은 접근을 방지하기 위해 최소 업계 표준 시스템 및 절차를 구현했습니다.<br />
&nbsp;3. 고객의 데이터는 이지로드 또는 이지로드 대리인이 시설을 유지 관리하는 지역 또는 국가에 저장하고 연산 처리할 수 있습니다.<br />
&nbsp;4. 이지로드는 해당 국가 수사기관의 영장, 법원의 판결 또는 결정 또는 정당한 법률에 의거한 경우에는 고객의 데이터를 요청기관에 고객에 대한 사전 통지 없이 제공하거나 공개할 수 있습니다.<br />
&nbsp;5. 이지로드가 제공하는 데이터 삭제 기능을 통해 고객이 데이터를 삭제하면 일반적으로 데이터는 모두 삭제되지만, 이지로드가 백업 서버 등에 보관한 백업본에는 데이터가 남아있을 수 있습니다.&nbsp;<br />
&nbsp; &nbsp; &nbsp; 이 백업본 데이터는 이지로드 자체의 백업본 데이터 운용정책에 따라 일정 기간(통상 영업일 기준 7일 정도이나, 항상 그렇지는 않음)이 경과한 후 자동적으로 소멸됩니다.<br />
&nbsp;6. 데이터가 유실된 경우<br />
&nbsp; &nbsp;저장된 전체 고객의 데이터 중 어느 고객의 데이터가 덜 중요한지, 또는 특정 고객의 데이터 중에서도 어떤 데이터가 더 중요한지 이지로드는 알 수 없습니다.<br />
&nbsp; &nbsp;이지로드는 저장된 데이터의 중요성 또는 우선순위에 관계없이 모든 고객 데이터에 대해 동일한 수준의 주의와 절차로써 관리합니다.<br />
&nbsp; &nbsp;통상적인 서비스 유지 활동의 일환으로 기능 업그레이드, 시스템 점검 및 교체, 데이터 마이그레이션, 기타 일상적인 서비스 제공과 관련한 행위 도중에,&nbsp;<br />
&nbsp; &nbsp;비록 이지로드의 귀책사유로 데이터의 유실 또는 변형이 발생하였다 하더라도 이지로드는 고객 입장에서의 데이터의 중요성 또는 우선순위에 관계없이&nbsp;<br />
&nbsp; &nbsp;최근 12개월 동안 고객이 이지로드에 지불한 금액보다 더 많은 배상금액을 지불하지 않습니다.<br />
&nbsp; &nbsp;고객의 실수나 관리소홀로 인해 분실되거나 복구 불가능한 데이터 일체에 대한 책임은 고객에게 있습니다.<br />
&nbsp;7.서비스의 오류 가능성<br />
&nbsp; &nbsp;컴퓨터 소프트웨어는 사람의 손으로 이루어지는 프로그램 코딩의 결과물이라는 특성으로 인하여 오류 가능성이 존재하고,&nbsp;<br />
&nbsp; &nbsp;이지로드가 제공하는 서비스도 입력, 저장, 계산처리, 출력 등에서 오류가 발생할 가능성을 포함하고 있습니다.<br />
&nbsp; &nbsp;고객이 상기와 같은 입력, 저장, 계산처리, 출력 등에서 발생한 오류을 인지하게 된 경우,&nbsp;<br />
&nbsp; &nbsp;이지로드에게 이를 즉시 통지하고, 오류를 인지하거나 통지 받은 이지로드는 오류를 시정하도록 최선을 다할 것입니다.<br />
&nbsp; &nbsp;만에 하나, 상기와 같은 입력, 저장, 계산처리, 출력 등에서 발생한 오류를 이지로드가 인지하거나 통지 받지 못 한 상황에서,&nbsp;<br />
&nbsp; &nbsp;또는 인지하거나 통지 받았다고 하더라도 오류의 시정 절차 중에 있거나 시정 우선순위에 밀려서 미처 시정되지 않은 상황에서,&nbsp;<br />
&nbsp; &nbsp;해당 오류로 인해 고객에 손해가 발생했을 때, 이지로드는 최근 12개월 동안 고객이 이지로드에 지불한 금액 한도 내에서 고객에게 발생한 손해를 배상 할 수 있습니다.&nbsp;<br />
&nbsp; &nbsp;또한 고객이 오류를 인지하였음에도 이를 즉시 이지로드에게 통지 하지 않은 경우, 이지로드는 손해배상 책임을 부담하지 않습니다.<br />
&nbsp;8. 고객은 다음 사항에 포함되는 데이터를 업로드, 게시, 배포, 링크, 출판, 재생산 또는 전송하지 않을 것이며,&nbsp;<br />
&nbsp; &nbsp; 제 3자에게 이를 허용하지 않을 것임을 동의하며, 다음 사항의 데이터가 게재될 경우 이지로드는 판단에 따라 해당 데이터를 즉시 삭제할 수 있습니다.<br />
&nbsp;* 불법, 사기, 명예 훼손, 외설, 포르노, 비속어, 협박, 증오, 희롱, 모욕 및 부적절하거나 불쾌한 정보 또는 통신 일체를 포함하며,&nbsp;<br />
&nbsp; &nbsp; &nbsp;꼭 언급한 내용이 아니더라도 타인을 격분하게 만들 수 있는 내용 일체 및 법적 형사 또는 민사책임이 적용되는 내용 일체<br />
&nbsp;* 타인을 사칭하거나 고객의 신분 또는 자격을 거짓 증명하는 내용 및 개인 사생활 일체를 침해할 수 있는 내용<br />
&nbsp;* 호객 행위, 투자 기회, 다단계 판매, 단체 문자(SMS), 스팸메일 등 제 3자가 원치 않는 상업적 자료<br />
&nbsp;* 바이러스, 트로이 목마, 웜 또는 기타 파괴성 있는 악성 소프트웨어와 자료<br />
&nbsp;* 고객이 법적인 권리를 지니지 않거나 저작권자 또는 지적재산권자의 허가를 받지 않은 정보 및 소프트웨어 일체<br />
&nbsp;* 기타 데이터 작성의 취지와 내용 등에 비추어 삭제하는 것이 객관적으로 적절한 데이터<br />
&nbsp;* 이지로드는 이지로드와 고객을 보호하거나 서비스를 정상적으로 운영하기 위해 데이터 일체를 감시 또는 수집할 수 있습니다.<br />
&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
제9조(개인정보처리의 위탁)<br />
회사는 관계법령이 정하는 바에 따라 이용자 등록정보를 포함한 이용자의 개인정보를 보호하기 위해 노력합니다.&nbsp;<br />
이용자 개인정보의 보호 및 사용에 대해서는 관련법령 및 회사의 개인정보 보호정책이 적용됩니다.&nbsp;<br />
단 회사의 공식사이트 이외의 웹에서 링크된 사이트에서는 회사의 개인정보 보호정책이 적용되지 않습니다.&nbsp;<br />
또한 회사는 이용자의 귀책사유로 인해 노출된 정보에 대해서 일체의 책임을 지지 않습니다.</p>

      <p>&nbsp;1.위탁 대상이 되는 개인정보란:&nbsp;<br />
&nbsp;&nbsp;&nbsp; &nbsp;① 이지로드가 제공하는 서비스에 접속하기 위해 고객이 등록한 사원의 일부 또는 전부 등 개인의 ID 정보 &nbsp;<br />
&nbsp;&nbsp;&nbsp; &nbsp;② 서비스 항목 중 인사/급여 기능에 고객이 입력한 사원의 일부 또는 전부 등 개인의 개인정보&nbsp;<br />
&nbsp; 고객은 서비스 이용을 위하여 고객 이외의 제3자의 개인정보를 이지로드가 제공하는 서비스에 입력하는 방식으로 이지로드에 개인정보처리를 위탁할 경우,&nbsp;<br />
&nbsp; 고객은 반드시 사전에 개인정보의 입력사실과 개인정보처리의 위탁사실을 이지로드에 알리고, [개인정보 보안관리 약정]을 이지로드와 체결하여야 합니다.<br />
&nbsp;2. 위탁업무의 목적 및 범위: 고객은 이지로드가 제공하는 서비스를 이용하기 위해 이지로드에게 사원 등의 개인정보처리를 이지로드와의 [개인정보 보안관리 약정]을 통해 위탁할 수 있으며, 고객이 시스템에 직접 입력한 자료가 그 범위에 포함됩니다.<br />
&nbsp;3. 업무목적 외 사용 금지: [개인정보 보안관리 약정]을 통해 위탁 받은 개인정보에 관하여, 이지로드는 서비스 기간은 물론 서비스 종료 후에도 위탁업무 수행 목적 범위를 넘어 개인정보를 이용하거나&nbsp;<br />
&nbsp; &nbsp;이를 제3자에게 제공 또는 누설해서는 안됩니다. 또한 고객이 입력한 개인정보는 원활한 서비스 제공을 위하여 고객이 서비스 해지의사를 밝힌 날로부터 최대 3개월까지 보관할 수 있습니다.<br />
&nbsp;4. 재위탁 제한: 이지로드는 고객의 사전 승낙을 얻은 경우를 제외하고 [개인정보 보안관리 약정]을 통해 위탁 받은 개인정보의 전부 또는 일부를 제3자에게 양도하거나 재위탁할 수 없습니다.&nbsp;<br />
&nbsp; &nbsp;이지로드가 재위탁받은 수탁회사를 선임한 경우, 이지로드는 그 사실을 즉시 고객에게 통보하여야 합니다.<br />
&nbsp;5. 고객정보의 기술적&middot;관리적 보호조치: 이지로드는 개인정보 보호법 제24조 제3항 및 제29조, 동법 시행령 제21조 및 제30조 [개인정보의 안전성 확보조치 기준]에 따라&nbsp;<br />
&nbsp; [개인정보 보안관리 약정]을 통해 위탁 받은 개인정보의 안정성 확보에 필요한 관리적, 기술적 조치를 취하고 있습니다.<br />
&nbsp;6. 개인정보의 접근통제: 이지로드는 [개인정보 보안관리 약정]을 통해 위탁 받은 개인정보를 이용할 수 있는 자를 제한하여야 하며, 정보처리스템에 접근이 필요한 경우,&nbsp;<br />
&nbsp; &nbsp;개별 ID 부여 및 패스워드를 설정하여 그 권한을 확인하고, 개인정보가 보관된 장소에 대한 출입통제장치, 감시카메라 설치 등 적절한 보안대책을 통해 개인정보 접근 현황을 통제 및 관리할 의무가 있습니다.<br />
&nbsp;7. 고객정보 관리 현황 점검: 고객은 [개인정보 보안관리 약정]을 체결하고, 협약에 따라 이지로드에게 다음의 사항을 관리하도록 요구할 수 있습니다.<br />
&nbsp;8. 손해배상: 이지로드는 이지로드의 임직원 및 기타 수탁자가 위탁 받은 업무를 수행함에 있어 [개인정보 보안관리 약정]을 위반하거나&nbsp;<br />
&nbsp; &nbsp;이지로드 또는 이지로드의 임직원 및 기타 수탁자의 귀책사유로 고객 또는 개인정보의 주체, 기타 제3자에게 손해가 발생한 경우, 이지로드는 그 손해를 배상해야 할 책임이 있습니다.<br />
&nbsp;9. 고객은 본 약관 제8조 제1항의 개인정보처리의 위탁이 필요하여 [개인정보 보안관리 약정]을 체결할 경우, 해당 정보 주체인 개인으로부터 위탁 처리에 관한 동의를 얻거나,&nbsp;<br />
&nbsp; 고객이 운영하는 인터넷 사이트 상 개인정보취급방침에서 개인정보처리의 위탁 사실을 공개하는 등 관계 법령이 요구하는 절차를 이행하여야 합니다.<br />
&nbsp; 만약 고객이 개인정보취급 위탁을 위하여 필요한 정보주체의 동의 내지 정보주체에 대한 공개 또는 고지의무 등을 제대로 이행하지 않아서 해당 정보 주체인 개인이 이의를 제기할 경우,&nbsp;<br />
&nbsp; 고객은 이지로드를 면책시키고, 자신의 비용과 책임으로 문제를 해결합니다.<br />
&nbsp;10. 본 약관 제9조 1항의 위탁대상이 되는 개인정보 이외에 고객이 서비스 이용 중에 임의로 입력하고 저장한 기타 개인정보는 이지로드가 개인정보의 입력사실을 인지할 수 없으므로 제8조의 데이터에 준하여 보호하고 관리합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
제10조 (이용신청의 승낙과 제한)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;1.&nbsp;&nbsp; &nbsp;회사는 전조의 각 항에 해당되는 내용을 회원이 성실히 기재하고 본 약관에 동의함과 동시에 본 서비스의 이용을 승낙하며&nbsp;<br />
&nbsp;&nbsp; &nbsp;다만&nbsp;&nbsp; &nbsp;아래(2)(3)항 각호에 해당하는 경우 회사는 승낙의 유보 철회 및 거절의 의사를 통지할 수 있습니다. &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;2.&nbsp;&nbsp; &nbsp;회사는 다음 각호의 경우에 승낙을 유보할 수 있습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp; &nbsp;①&nbsp;&nbsp; &nbsp;설비 또는 장비에 여유가 없는 경우&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp; &nbsp;②&nbsp;&nbsp; &nbsp;기술상 서비스를 처리하지 못할 장애 사항이 발생한 경우&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp; &nbsp;③&nbsp;&nbsp; &nbsp;기타 회사가 필요하다고 인정한 경우&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp; &nbsp;④&nbsp;&nbsp; &nbsp;기존회원의 서비스 이용에 장애가 되는 경우&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;3.&nbsp;&nbsp; &nbsp;회사는 다음 각호의 경우에 승낙을 철회 하거나 거절할 수 있습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp; &nbsp;①&nbsp;&nbsp; &nbsp;개인정보 및 등록정보가 허위로 기재한 경우&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp; &nbsp;②&nbsp;&nbsp; &nbsp;다른 사람 명의로 이용 신청한 경우&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp; &nbsp;③&nbsp;&nbsp; &nbsp;공공질서 또는 건전한 미풍양속을 저해할 우려가 있는 경우&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp; &nbsp;④&nbsp;&nbsp; &nbsp;기타 회원으로서의 부적절한 행위를 할 우려가 있다고 인정되는 경우&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;4.&nbsp;&nbsp; &nbsp;회사는 비실명인 회원&nbsp;&nbsp; &nbsp; 만14세 미만 회원&nbsp;&nbsp; &nbsp; 청소년 보호법상의 보호대상인 회원&nbsp;&nbsp; &nbsp; 만20세 미만의 회원&nbsp;&nbsp; &nbsp; 외국인 회원&nbsp;&nbsp; &nbsp; 외국법인 회원에 대하여 일정한 서비스의 제공을 제한 할 수 있습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;</p>

      <p>제11조 (이용자ID 부여 및 변경)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;1.&nbsp;&nbsp; &nbsp;회원ID는 변경이 불가하며 부득이한 사유로 인하여 변경하고자 하는 경우에는 해당 ID를 해지하고 재가입해야 합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;2.&nbsp;&nbsp; &nbsp;회원은 자신의ID를 다음 각 호에 해당하는 경우 회사의 요청따라 변경 할 수 있습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp; ①&nbsp;&nbsp; &nbsp;회원ID가 이용자를 식별할 수 있는 정보 등으로 등록되어 사생활침해가 우려되는 경우&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp; ②&nbsp;&nbsp; &nbsp;타인에게 혐오감을 주거나 미풍양속에 어긋나는 경우&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp; ③&nbsp;&nbsp; &nbsp;기타 합리적인 사유가 있는 경우&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;</p>

      <p>제12조 계약당사자의 의무&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;1. (회사의 의무)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp; ①&nbsp;&nbsp; &nbsp;회사는 이용고객이 희망한 서비스 제공 개시일에 특별한 사정이 없는 한 서비스를 이용할 수 있도록 하여야 합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp; ②&nbsp;&nbsp; &nbsp;회사는 계속적이고 안정적인 서비스의 제공을 위하여 설비에 장애가 생기거나 멸실된 때에는 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp; ③&nbsp;&nbsp; &nbsp;회사는 개인정보 보호를 위해 보안시스템을 구축하며 개인정보 보호정책을 공시하고 준수합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp; ④&nbsp;&nbsp; &nbsp;회사는 이용고객으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 적절한 절차를 거쳐 즉시 처리하여야 합니다.&nbsp;<br />
&nbsp; &nbsp; &nbsp; &nbsp;다만 즉시 처리가 곤란한 경우는 이용자에게 그 사유와 처리일정을 통보하여야 합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;2. (회원의 의무)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp; ①&nbsp;&nbsp; &nbsp;이용자는 회원가입 신청 또는 회원정보 변경 시 실명으로 모든 사항을 사실에 근거하여 작성하여야 하며&nbsp;&nbsp; &nbsp; 허위 또는 타인의 정보를 등록할 경우 일체의 권리를 주장할 수 없습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp; ②&nbsp;&nbsp; &nbsp;회원은 본 약관에서 규정하는 사항과 기타 회사가 정한 제반 규정&nbsp;&nbsp; &nbsp; 공지사항 등 회사가 공지하는 사항 및 관계법령을 준수하여야 하며&nbsp;&nbsp; &nbsp; 기타 회사의 업무에 방해가 되는 행위&nbsp;&nbsp; &nbsp; 회사의 명예를 손상시키는 행위를 해서는 안됩니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp; ③&nbsp;&nbsp; &nbsp;회원은 주소&nbsp;&nbsp; &nbsp; 연락처&nbsp;&nbsp; &nbsp; 전자우편 주소 등 이용계약사항이 변경된 경우에 해당 절차를 거쳐 이를 회사에 즉시 알려야 합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp; ④ 회원은(4)회원은 자신의 ID와 비밀번호의 관리할 책임이 있습니다. 회원은 자신의 ID와&nbsp;&nbsp; &nbsp; 비밀번호 관리소홀&nbsp;&nbsp; &nbsp; 부정사용에 의하여 발생하는 모든 결과에 대한 책임을 부담하고&nbsp;&nbsp; &nbsp; 회사는 회사가 관계법령 및 &#39;개인정보 보호정책&#39;에 의거하여 그 책임을 지는 경우를 제외하고 이에 대한 책임이 없습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp; ⑤ 본 회원은 서비스 이용 시 자신의 정보의 변동 사항이 있을 경우 이를 변경해야 하며&nbsp;&nbsp; &nbsp; 변경을 성실히 이행하지 않아 생기는 불이익은 회사에서는 책임지지 않습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp; ⑥ 회원은 회사의 사전 승낙 없이 서비스를 이용하여 영업활동을 할 수 없으며&nbsp;&nbsp; &nbsp;그 영업활동의 결과에 대해 회사는 책임을 지지 않습니다.&nbsp;<br />
&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;또한 회원은 이와 같은 영업활동으로 회사가 손해를 입은 경우 회원은 회사에 대해 손해배상의무를 지며 회사는 해당 회원에 대해 서비스 이용제한 및 적법한 절차를 거쳐 손해배상 등을 청구할 수 있습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp; ⑦ 회사의 명시적 동의가 없는 한 서비스의 이용권한 기타 이용계약상의 지위를 타인에게 양도 증여할 수 없으며 이를 담보로 제공할 수 없습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp; ⑧ 회원은 회사 및 제 3자의 지적 재산권을 침해해서는 안됩니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp; ⑨ 회원은 다음 각 호에 해당하는 행위를 하여서는 안되며 해당 행위를 하는 경우에 회사는 회원의 서비스 이용 제한 및 적법 조치를 포함한 제재를 가할 수 있습니다&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp; &nbsp; - 이용자 ID를 타인과 거래하는 행위&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;- 회사의 운영진&nbsp;&nbsp; &nbsp; 직원 또는 관계자를 사칭하는 행위&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;- 회사로부터 특별한 권리를 부여 받지 않고 회사의 프로그램을 변경하거나&nbsp;&nbsp; &nbsp; 회사의 서버를 해킹하거나&nbsp;&nbsp; &nbsp; 웹사이트 또는 게시된 정보의 일부분 또는 전체를 임의로 변경하는 행위&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;- 서비스에 위해를 가하거나 고의로 방해하는 행위&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;- 본 서비스를 통해 얻은 정보를 회사의 사전 승낙 없이 서비스 이용 외의 목적으로 복제하거나&nbsp;&nbsp; &nbsp; 이를 출판 및 방송 등에 사용하거나&nbsp;&nbsp; &nbsp; 제 3자에게 제공하는 행위&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;- 공공질서 및 미풍양속에 위반되는 저속&nbsp;&nbsp; &nbsp; 음란한 내용의 정보&nbsp;&nbsp; &nbsp; 문장&nbsp;&nbsp; &nbsp; 도형&nbsp;&nbsp; &nbsp; 음향&nbsp;&nbsp; &nbsp; 동영상을 전송&nbsp;&nbsp; &nbsp; 게시&nbsp;&nbsp; &nbsp; 전자우편 또는 기타의 방법으로 타인에게 유포하는 행위<br />
&nbsp;&nbsp; &nbsp;- 모욕적이거나 개인신상에 대한 내용이어서 타인의 명예나 프라이버시를 침해할 수 있는 내용을 전송&nbsp;&nbsp; &nbsp; 게시&nbsp;&nbsp; &nbsp; 전자우편 또는 기타의 방법으로 타인에게 유포하는 행위&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;- 다른 이용자를 희롱 또는 위협하거나&nbsp;&nbsp; &nbsp; 특정 이용자에게 지속적으로 고통 또는 불편을 주는 행위&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;- 회사의 승인을 받지 않고 다른 사용자의 개인정보를 수집 또는 저장하는 행위&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;- 범죄와 결부된다고 객관적으로 판단되는 행위&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;- 본 약관을 포함하여 기타 회사가 정한 제반 규정 또는 이용 조건을 위반하는 행위&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;- 기타 관계법령에 위배되는 행위&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;</p>

      <p>제13조(서비스의 이용)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;1.&nbsp;&nbsp; &nbsp;회사는 회원의 이용 신청을 승낙한 때부터 서비스를 개시합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;2.&nbsp;&nbsp; &nbsp;유료서비스의 경우에는 회원이 유료 서비스 이용요금을 회사에 지불하고 지정된 일로부터 유료 서비스를 개시합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;3.&nbsp;&nbsp; &nbsp;회사의 업무상 또는 기술상의 장애로 인하여 서비스를 개시하지 못하는 경우 사이트에 공시 하거나 회원에게 이를 통지합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;</p>

      <p>제14조(서비스의 이용시간)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;1.&nbsp;&nbsp; &nbsp;서비스 이용시간은 연중 무휴 1일 24시간을 원칙으로 하며 단 회사의 업무상이나 기술상의 이유로 서비스가 일시 중지될 수 있고 또한 운영 상의 목적 및 시스템 점검 등으로 회사가 정한 기간에는 서비스가 일시 중지 될 수 있습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;2.&nbsp;&nbsp; &nbsp;회사는 서비스 일시 중지 시 사전 또는 사후에 이를 공지 합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;3.&nbsp;&nbsp; &nbsp;회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용 가능한 시간을 별도로 정할 수 있으며&nbsp;&nbsp; &nbsp; 이 경우 그 내용을 공지 합니다.<br />
&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
제15조(유료 서비스 이용관련)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;1.&nbsp;&nbsp; &nbsp;회사가 정한 사용요건과 약관에 동의한 회원만이 서비스를 이용할 수 있습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;2.&nbsp;&nbsp; &nbsp;결제 승인은 회원이 선택한 결제방법으로 신청한 경우 회사가 승낙함으로써 성립되며 회사는 결제 요청 시 회원이 입력한 내용을 모두 사실로 간주 하며 허위로 내용을 입력한 회원은 법적인 보호를 받을 수 없습니다.&nbsp;<br />
&nbsp; &nbsp; &nbsp; &nbsp;또한 본 항을 어긴 회원의 경우 당사 사용을 중지하거나 회원자격 박탈 시킬 수 있으며 재가입을 불허할 수 있습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;3.&nbsp;&nbsp; &nbsp;회사는 신용불량자로 등록된 회원이나 대금 지급을 연체하는 회원에 대하여 유료 서비스 이용을 제한할 수 있습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;4.&nbsp;&nbsp; &nbsp;회사 서비스의 중대한 하자에 의하여 서비스의 기능이 상실되었거나 불량했을 경우가 타당성이 인정되는 경우 사용기간 연장 혹은 해당 기능 복원으로 보상 받을 수 있습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;5.&nbsp;&nbsp; &nbsp;회원은 다음 각 호의 사유가 있으면 적법한 절차를 거친 후 회원 자신이 직접 유료 서비스 이용을 하기 위해 소비한 금액을 회사로부터 환불 받을 수 있습니다.&nbsp;<br />
&nbsp; &nbsp; &nbsp; &nbsp;단 회사는 환불 수수료(총 결제액*10%) + 실제 이용금액(총 결제액 * 실제이용일/전체이용일) 위의 금액 을 제하고 환불할 수 있습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp; &nbsp; &nbsp; &nbsp;환불수수료는 결제와 관련된 결제대행사 결제수수료 송금수수료 등 해지절차에 필요한 수수료를 합한 최소 금액 입니다.&nbsp;<br />
&nbsp; &nbsp; &nbsp; &nbsp;단 해지의 사유가 회사의 귀책 사유일 경우 환불 수수료는 차감하지 않습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;①&nbsp;&nbsp; &nbsp;유료 서비스 신청을 했으나 사용할 수 있는 서비스가 전무하며 그에 대한 책임이 전적으로 당사에 있을 경우 (단 시스템 정기 점검 등 불가피한 경우를 제외)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;②&nbsp;&nbsp; &nbsp;유료 서비스 신청을 위하여 사용료를 결제 하였으나 유료 서비스가 3일이내에 실시 되지 않아 회원이 환불을 요청한 경우&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;③&nbsp;&nbsp; &nbsp;회원의 귀책으로 회사가 당해 회원에게 해지를 통보하는 경우&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;④&nbsp;&nbsp; &nbsp;기타 소비자 보호를 위하여 당사에서 따로 정하는 경우&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;6.&nbsp;&nbsp; &nbsp;해지 또는 하위버전으로 서비스를 변경하는 고객이 선납금액에 할인율을 적용 받은 경우 사용금액을 할인율이 적용되지 않은 정상금액을 기준으로 차감 계산합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;7.&nbsp;&nbsp; &nbsp;회사의 환불절차는 다음과 같습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;①&nbsp;&nbsp; &nbsp;회원은 유료서비스 신청을 한 후 14일 이내에 회사로 연락 하여 환불을 신청해야 합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;②&nbsp;&nbsp; &nbsp;회사는 환불 신청 후 정당함을 심사하여 정당한 이유가 있음으로 판단된 회원에게는 미 사용한 기간을 일할 계산하여 환불 지급 합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;③&nbsp;&nbsp; &nbsp;유료 서비스 신청 후 14일 이상이 지난 후에는 환불신청을 하여도 환불을 받으실 수 없습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;④&nbsp;&nbsp; &nbsp;회사는 해지 시 환불 절차를 병행하여 진행합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;⑤&nbsp;&nbsp; &nbsp;회원은 해지/ 환불 시 회사에서 정한 절차에 따라 진행 해야 합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;8.&nbsp;&nbsp; &nbsp;미납금액이 있는 회원이 탈퇴신청 할 경우 미납액을 해결하기 전에 탈퇴가 불가능하며&nbsp;&nbsp; &nbsp; 회사는 미납액에 대해 탈퇴를 신청한 회원에게 청구할 권리와 의무가 있습니다&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;9.&nbsp;&nbsp; &nbsp;결제수단을 이용하여 유료서비스를 신청한 경험이 없는 회원이 해지할 경우&nbsp;&nbsp; &nbsp; 남아있던 사용 기간은 전일 소멸됩니다&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;10.회사는 당 서비스와 관련하여 이용자가 제기하는 정당한 의견이나 불만을 반영하여 우선적으로 그 사항을 처리합니다.&nbsp;<br />
&nbsp;&nbsp;&nbsp; &nbsp;다만 신속한 처리가 곤란한 경우에는 이용자께 그 사유와 처리일정을 즉시 통보해 드립니다.&nbsp;<br />
&nbsp;&nbsp;&nbsp; &nbsp;회사와 이용자간에 발생한 분쟁은 &ldquo;전자문서 및 전자거래 기본법 제32조&rdquo; 의하여 설치된 전자거래분쟁조정위원회의 조정에 따를 수 있습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
제16조(정보의 제공)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;1.&nbsp;&nbsp; &nbsp;회사는 회원에게 서비스 이용에 필요가 있다고 인정되는 각종 정보에 대해서 전자우편이나 서신우편 SMS 등의 방법으로 회원에게 제공할 수 있습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;2.&nbsp;&nbsp; &nbsp;회사는 서비스 개선 및 회원 대상의 서비스 소개 등의 목적으로 회원의 동의 하에 추가적인 개인 정보를 요구할 수 있습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
제17조(계약 변경 및 해지)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;1.&nbsp;&nbsp; &nbsp;회원은 이용계약해지를 원할 경우 회원 본인이 직접 온라인을 통하여 회사에 해지 시청을 해야 합니다&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;2.&nbsp;&nbsp; &nbsp;이지로드 페이지 내 &ldquo;탈퇴&rdquo;를 클릭하셨을 경우 본인 확인 절차 이후 정상적으로 해지 처리 할 수 있습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;3.&nbsp;&nbsp; &nbsp;회원 탈퇴 처리가 완료된 시점에서 이용자의 개인정보의 일부는 수집 이용목적에 대해서 다음과 같은 사유로 인해 보존됩니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;①&nbsp;&nbsp; &nbsp;회원 탈퇴 개인정보: 아이디, 사업자명, 사업자 번호, 사업자 소재지&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;보존 사유: 동일 아이디로 재가입이 불가능 하며 본인 확인에 대한 기록보존&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;②&nbsp;&nbsp; &nbsp;본 사항은 전자상거래 등 소비자 보호에 관한 법률로 유지됩니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;4.&nbsp;&nbsp; &nbsp;회사는 회원이 각호의 사항에 해당되는 행위를 하였을 경우 사전 통지 없이 이용계약을 정지 또는 해지 할 수 있습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;①&nbsp;&nbsp; &nbsp;회원 정보를 허위 입력한 경우&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;②&nbsp;&nbsp; &nbsp;타인의 정보를 이용하여 회원이 된 경우&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;③&nbsp;&nbsp; &nbsp;여러 불법적이 수단으로 본 사이트의 운영을 방해한 경우&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;④&nbsp;&nbsp; &nbsp;유료 서비스 사용기간을 제3자와 유상거래 하거나 현금으로 전환하는 행위를 한경우&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;⑤&nbsp;&nbsp; &nbsp;기타 타 회원 이나 회사에 피해가 가는 경우<br />
&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
제18조(이용 제한)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;1.&nbsp;&nbsp; &nbsp;회사는 회원이 서비스 이용내용에 &ldquo;회원의 의무&rdquo; 내용을 위반하거나&nbsp;&nbsp; &nbsp;&nbsp;<br />
&nbsp; &nbsp; &nbsp; &nbsp;다음 각호에 해당하는 경우 서비스 이용을 제한할 수 있습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;①&nbsp;&nbsp; &nbsp;미풍양속을 저해하는 비속한 ID 및 별명 사용&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;②&nbsp;&nbsp; &nbsp;타 이용자에게 심한 모욕을 주거나&nbsp;&nbsp; &nbsp; 서비스 이용을 방해한 경우&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;③&nbsp;&nbsp; &nbsp;기타 정상적인 서비스 운영에 방해가 될 경우&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;④&nbsp;&nbsp; &nbsp;정보통신 윤리위원회 등 관련 공공기관의 시정요구가 있는 경우&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;⑤&nbsp;&nbsp; &nbsp;불법사업자 또는 홈페이지를 운영하는 경우&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;⑥&nbsp;&nbsp; &nbsp;사용소프트웨어나 크랙파일을 올리는 경우&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;⑦&nbsp;&nbsp; &nbsp;정보통신윤리 위원회의 심의 세칙 제7조에 어긋나는 음란물을 게재한 경우&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;⑧&nbsp;&nbsp; &nbsp;반국가적 행위의 수행을 목적으로 하는 내용을 포함한 경우&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;⑨&nbsp;&nbsp; &nbsp;저작권이 있는 글을 무단 복제하거나&nbsp;&nbsp; &nbsp; MP3를 홈 계정에 올린 경우&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;2.&nbsp;&nbsp; &nbsp;상기 이용제한 규정에 따라 서비스를 이용하는 회원에게 서비스 이용에 대해여 별도 공지 없이 서비스 일시 정지&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp;&nbsp; &nbsp;초기화 이용 계약 해지 등을 취할 수 있습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
제 19조 (손해배상)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;1.&nbsp;&nbsp; &nbsp;회원이 본 약관의 규정을 위반 또는 부당한 행위로 인하여 회사에 손해가 발생되는 경우 이 약관을 위반한 회원은 회사에 발생하는 모든 손해를 배상 하여야 합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;2.&nbsp;&nbsp; &nbsp;회원이 서비스를 이용함에 있어 행한 불법행위나 본 약관 위반행위로 인하여 회사가 당해 회원 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한&nbsp;<br />
&nbsp;&nbsp;&nbsp; &nbsp;각종 이의제기를 받는 경우 당해 회원은 자신의 책임과 비용으로 회사를 면책 시켜야 하며&nbsp;&nbsp; &nbsp;&nbsp;<br />
&nbsp;&nbsp;&nbsp; &nbsp;회사가 면책되지 못한 경우 당해 회원은 그로 인하여 회사에 발생한 모든 손해를 배상하여야 합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;</p>

      <p>제 20조 (면책사항)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;1.&nbsp;&nbsp; &nbsp;회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제 됩니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;2.&nbsp;&nbsp; &nbsp;회사는 회원의 귀책사유로 인한 서비스의 이용장애에 대하여 책임이 면제 됩니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;3.&nbsp;&nbsp; &nbsp;회사는 서비스용 설비의 보수 교체 정기점검 공사 등 부득이한 사유로 발생한 손해에 대한 책임이 면제 됩니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;4.&nbsp;&nbsp; &nbsp;회사는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제 됩니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;5.&nbsp;&nbsp; &nbsp;회사는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며 그밖에 서비스를 통하여 얻은 자료로 인한 손해 등에 대하여도 책임을 지지 않습니다.&nbsp;<br />
&nbsp;&nbsp;&nbsp; &nbsp;회사는 회원이 사이트에 게재한 정보 자료에 대하여 사실의 신뢰도 및 정확성 등 내용에 대하여는 책임이 면제 됩니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;6.&nbsp;&nbsp; &nbsp;회사는 회원 상호간 또는 회원과 제3자 상호간에 서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;7.&nbsp;&nbsp; &nbsp;회사는 이용자의 컴퓨터 오류에 의해 손해가 발생한 경우 또는 회원이 신상 정보 및 전자 우편주소를 부실하게 기재하여 손해가 발생한 경우 책임이 면제 됩니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;8.&nbsp;&nbsp; &nbsp;회사는 고객에게 무료로 제공하는 서비스의 이용과 관련해서는 어떠한 손해도 책임이 면제 됩니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;</p>

      <p>제 21조 (재판권 및 준거법)&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;1.&nbsp;&nbsp; &nbsp;회사와 회원간 제기된 소송은 대한민국 법을 준거법으로 합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;2.&nbsp;&nbsp; &nbsp;서비스 이용 중 발생한 회원과 회사간의 분쟁에 대해 소송이 제기될 경우 회사의 본사 소재지를 관할하는 법원을 관할법원으로 합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;<br />
&nbsp;3.&nbsp;&nbsp; &nbsp;해외에 주소나 거소가 있는 회원의 경우 회사와 회원간 발생한 분쟁에 관한 소송은 전항에도 불구하고 대한민국 서울남부지방법원을 관할법원으로 합니다.&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;</p>

      </div>
    </Popup>
  );
});

export default ServicePolicyDialog;