
import ContactUsStore from 'store/ContactUsStore'
import SignUpStore from 'store/SignUpStore';
import LoginStore from 'store/LoginStore';


export default class RootStore {
    constructor() {
        this.contactUsStore = new ContactUsStore(this);
        this.signUpStore = new SignUpStore(this);
        this.loginStore = new LoginStore(this);
    }
}