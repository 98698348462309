import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import EzHero from 'pages/common/ez_hero';

import MajorUseCase from "components/features/MajorUseCase.js";
import MajorSystemFeature from "components/features/MajorUseCase.js";

import EzMajorFeature from "pages/common/ez_major_feature";
import EzPricing from "pages/common/ez_price";
import EzFaq from 'pages/common/ez_faq';
import EzFooter from 'pages/common/ez_footer';

import OurValues from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";

import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";

import GetStarted from "components/cta/GetStarted";

import prototypeIllustrationImageSrc from "images/large_images/meeting_illust.png";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

import SupportIconImage from "images/icons/counsel.png";
import OnAndOffImage from "images/icons/on_and_off.png";
import MoneyImage from 'images/icons/money.png';
import MobileIconImage from 'images/icons/mobile.png';
import GroomyFace from 'images/icons/groomy_face.png'
import BarCodeImage from "images/icons/on_and_off.png";
import EzUsageImage from "images/icons/ez_icon.png";
import EarthImage from "images/icons/earth.png";
import CloudImage from "images/icons/cloud.png";
import ManyPeopleImage from 'images/icons/many_people.png'
import FreeUpgradeImage from 'images/icons/free_update.png'

export default () => {
  const HighlightedText = tw.span`text-primary-500`;
  return (
    <AnimationRevealPage>
      <EzHero />
      <MajorUseCase
        heading={
          <>
            이지로드는 <HighlightedText>누구에게 필요할까요?</HighlightedText>
          </>
        }
        cards = {[
          { imageSrc: OnAndOffImage, 
            title: "오프라인 온라인 통합", 
            description: "온/오프라인 매장 관리 따로 하지 마세요. 이지로드 하나로 간편하게 "
          }, 
          {
            imageSrc: GroomyFace,
            title: "오배송이 싫으신 분",
            description: "이지로드의 주문연동 시스템은 자동으로 주문을 수집하여 원클릭으로 매출전표, 상품매칭, 송장발행을 합니다"
          },
          { 
            imageSrc: SupportIconImage, 
            title: "고객주문 통합 관리",
            description: "이지로드에서 한번에 연동된 모든 쇼핑몰의 주문내역, 고객통합관리를 할 수 있습니다"
          },
          { imageSrc: MoneyImage, 
            title: "합리적 가격", 
            description: "원 1만원으로 매입, 매출, 주문관리, 재고관리까지 사용자 수 상관없이 무제한으로 사용할 수 있습니다"
          },
          { imageSrc: BarCodeImage, 
            title: "바코드기반 재고관리", 
            description: "별도의 장비없이 휴대폰으로 정확한 재고파악은 문론, 바코드까지 실시간으로 관리하세요"
          },
          { imageSrc: EzUsageImage, 
            title: "쉬운 사용법", 
            description: "별도의 교육이 필요하지 않고, 엑셀처럼 쉽게 누구나 사용할 수 있습니다"
          }, 
        ]}
      />
      <MajorSystemFeature
        heading={
            <>
              이지로드 <HighlightedText>시스템 특징</HighlightedText>
            </>
        }
        cards = {[
          { imageSrc: EarthImage, 
            title: "웹 기반 시스템", 
            description: "별도 설치 없이 어디서나 바로 사용할 수 있습니다 "
          }, 
          {
            imageSrc: CloudImage,
            title: "클라우드 데이터 보관",
            description: "클라우드 기반의 고도화된 보안 시스템으로 데이터 분실 걱정없는 높은 신뢰도"
          },
          { 
            imageSrc: MobileIconImage, 
            title: "모바일 완벽 연동",
            description: "모바일 앱이 실시간으로 연동되어 이동형 오피스 완벽 지원"
          },
          { imageSrc: ManyPeopleImage, 
            title: "사용자 무제한", 
            description: "운영자 시스템을 통해 자유롭게 사용자를 추가 가능. 추가 비용 제로"
          },
          { imageSrc: FreeUpgradeImage, 
            title: "무료 업그레이드", 
            description: "사용자의 요구 사항을 지속적으로 반영하는 무료 업그레이드"
          },
          { imageSrc: MoneyImage, 
            title: "저렴한 가격", 
            description: "가입비 0원, 추가 비용 없이 월 만원에 이 모든 기능을"
          }, 
        ]}
      />
      <EzPricing/>
      
      <EzMajorFeature/>
      
      <OurValues
        subheading={""/*<Subheading>이지로드는</Subheading>*/}
        heading={
          <>
            이지로드는 <br/><HighlightedText>작지만 유용한 솔루션을</HighlightedText><br/>만들고 있습니다. 
          </>
        }
        description={"누구나 사용할 수 있는 쉽고 업무에 꼭 필요한 프로그램을 만들고자 노력합니다. 이지로드 도입은 고객 업무의 효율성 극대화를 의미합니다. 성공으로 가는 쉬운 길 이지로드와 함께 하세요 "}
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "합리적가격",
            description: "꼭 필요한 기능만 구현한 합리적 가격",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BriefcaseIcon,
            title: "전문가 시스템",
            description: "전문가의 노하우가 녹아든 이지로드는 당신 사업 성공의 동반자 입니다.",
            iconContainerCss: tw`bg-red-300 text-red-800`
          }
        ]}
      />
      
      <Testimonial
        subheading={""/* <Subheading>고객사용후기</Subheading> */}
        heading={
          <>
            이지로드 <HighlightedText>사용후기</HighlightedText>
          </>
        }
        description={"이지로드 고객님들의 소중한 사용 후기입니다. "}
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "이렇게 좋은 기능이 만원이라니 참 좋습니다",
            quote:
              `이지로드를 사용하면서 첫째로 비용이 절감 되었습니다. 직원이 늘어날 수록 사용비용도 증가되었던 기존의 경영프로그램은 재고관리도 맞출수가 없고, 어쩌다 맞출라치면 서버가 자꾸 다운되서 업무상 어려움이 많았었습니다. 
              온라인 쇼핑몰을 같이 운영하다보니 쇼핑몰 초창기에는 오배송이 너무 많아 클레임 전화를 전담하는 직원을 따로 두어야 할 정도였죠. 심각한 클레임 전화는 직원이 너무 힘들어 퇴사까지 하는 경우도 여러 번이었습니다. 
              직원이 바뀔 때마다 상품을 잘 모르는 이유로 발생하는 오배송을 줄이기 까지는 절대적 시간이 늘 필요했죠. 
              이지로드를 사용한 후에 자동 매핑이 되니 잠깐만 알려줘도 실수를 하지 않게 되어 고객도 직원도
               모두 만족해 하고 있습니다.  이지로드는 현장에서 직접 사업을 하시던 분이 만든 제품이라 현장감이 
              남다르다는 것이 큰 장점인것 같아요. 사용하지 않는 거추장 스러운 기능들을 빼고, 꼭 필요한 기능들만 
              남긴 알짜베기 같은 프로그램이라는 말이 딱 맞습니다. 
              앞으로도 더 좋은 기능들이 계속 추가된다 하니 기대감이 큽니다. 
              사장님 애써 주세요~~그리고 번창하세요~`,
            customerName: "대표이사",
            customerTitle: "R 조명몰"
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "모바일 재고 관리 정말 빠르고 편합니다",
            quote:
              "회사의 규모도 점점 커지고 물건도 많아지다 보니 제대로 된 재고관리 시스템이 필요하던 찰나에 이지로드를 알게 되었고, 그때 방문해주셨던 영업사원분의 친절한 설명과 미소 덕분에 이지로드를 사용게 되었습니다.덕분에 재고관리도 잘되고 택배누락도 없이 잘 사용하고 있습니다.",
            customerName: "대표이사",
            customerTitle: "달콤함 소품몰"
          }
        ]}
      />
      <EzFaq/>
      <GetStarted
        text="이지로드는 데모 버전이 없습니다.지금 바로 체험해 보세요"
        primaryLinkUrl="/demo_login"
        primaryLinkText="이지로드 체험하기"
        secondaryLinkUrl="/contact_us"
        secondaryLinkText="고객문의"
      />
      <EzFooter />
    </AnimationRevealPage>
  );
}
