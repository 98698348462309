

module.exports = Object.freeze({
        EZ_COMPANY_ID                   : "_ez_company_id",			// 몰 아이디
        USER_ID			        : "_user_id",				// 아이디
        USER_PW			        : "_user_pw",				// 비밀번호     
        USER_COMP_NAME		        : "_user_comp_name",			// 상호
        USER_CEO_NAME		        : "_user_ceo_name",			// 대표자 성명
        USER_POLICY_AGE_LIMIT_ACCEPT    : "_user_policy_age_limit_accept",	// 나이 제한 확인 
	USER_POLICY_PRIVATE_INFO_ACCEPT	: "_user_policy_private_info_accept",	// 개인정보 약관 동의 
	USER_POLICY_SERVICE_ACCEPT	: "_user_policy_service_accept",	// 서비스 이용 약관 동의 
        USER_POLICY_MARKETING_ACCEPT	: "_user_policy_marketing_accept",	// 마케팅 이용 약관 동의
        USER_POLICY_STATE_ACCEPT	: "PS01",				// 사용자 이용 약관 상태(동의)
	USER_POLICY_STATE_DENY		: "PS02",				// 사용자 이용 약관 상태(동의)
        CS_USER_EMAIL_FIELD	        : "_cs_user_email_field",		// 고객 이메일 주소
        CS_USER_NAME_FIELD	        : "_cs_user_name_field",		// 고객 성명 
        CS_TITLE_FIELD		        : "_cs_title_field",			// 고객문의 제목
        CS_CONTENT_FIELD	        : "_cs_content_field"			// 고객문의 내용 
});