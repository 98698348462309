import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line

import ComponentRenderer from "ComponentRenderer.js";
import MainLandingPage from "MainLandingPage.js";
import AboutUs from './pages/AboutUs'
import MajorFeature from './pages/MajorFeature'
import Pricing from './pages/Pricing'
import ContactUs from './pages/ContactUs'
import SignUp from './pages/member/Signup';
import Login from './pages/Login';
import DemoLogin from "pages/DemoLogin";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//import { Provider } from 'mobx-react'; // MobX 에서 사용하는 Provider
import RootStore from 'store/index';

import { positions, Provider as AltProvider } from "react-alert";
import AlertTemplate from "react-alert-template-mui";

export default function App() {

  const root = new RootStore(); // *** 루트 스토어 생성

  const options = {
    timeout: 5000,
    position: positions.MIDDLE,
  };

  return (
      <AltProvider template={AlertTemplate} {...options}>
      <Router>
        <Switch>
          <Route path="/components/:type/:subtype/:name">
            <ComponentRenderer />
          </Route>
          <Route path="/components/:type/:name">
            <ComponentRenderer />
          </Route>
          <Route path="/aboutUs">
            <AboutUs />
          </Route>
          <Route path="/pricing">
            <Pricing />
          </Route>
          <Route path="/sign_up">
            <SignUp signUpStore={root.signUpStore} />
          </Route>
          <Route path="/contact_us">
            <ContactUs contactUsStore={root.contactUsStore} />
          </Route>
          <Route path="/major_feature">
            <MajorFeature />
          </Route>
          <Route path="/login">
            <Login signUpStore={root.signUpStore} />
          </Route>
          <Route path="/demo_login">
            <DemoLogin loginStore={root.loginStore} />
          </Route>
          <Route path="/">
            <MainLandingPage />
          </Route>
        </Switch>
      </Router>
      </AltProvider>
  );
}
