import { observable, action } from 'mobx';

import * as EzHttpUtils from 'common/EzHttpUtils';
import * as EzURLConst from 'common/EzURLConst';
import * as EzFormConst from 'common/EzFormConst';

/**
 * Project : ezweb 
 * 
 * Contact Us 관련 스토어 입니다. 
 * 
 * create by choi young ho. 
 * 2020.11.08 
 */
export default class ContactUsStore {

    /**
     * Creates an instance of ContactUsStore.
     * root Store 객체를 지정 합니다. 
     * `    
     * @param {*} root
     * @memberof ContactUsStore
     */
    constructor(root, functions) {
        this.root = root;
        //if (__DEV__) {
        //    functions.useFunctionsEmulator('http://localhost:5001');
        //}
    }

    /**
     * 
     * 관리자에게 
     * 불편 사항을 
     * 이메일로 접수 요청 합니다. 
     * 
     * @param {*} emailaddr : 고객 이메일 주소 
     * @param {*} userName  : 고객 이름 
     * @param {*} title     : 제목 
     * @param {*} content   : 내용 
     */
    @action doSendEmail = async (emailaddr, userName, title, content) => {
        let result = "";
        try {
            console.log("Try to Send email... : ", emailaddr);

            let param = {
                [EzFormConst.CS_USER_EMAIL_FIELD]: emailaddr,
                [EzFormConst.CS_USER_NAME_FIELD]: userName,
                [EzFormConst.CS_TITLE_FIELD]: title,
                [EzFormConst.CS_CONTENT_FIELD]: content,
            }
            let json = await EzHttpUtils.doSendPostRequest(EzURLConst.CUSTOMER_CONTACT_US_URL, param);
        }
        catch (e) {
            throw e;
        }
        return result;
    }
}