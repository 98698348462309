
import React from "react";
import Pricing from "components/pricing/ThreePlans.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";

const PlanAction = tw.div`px-4 sm:px-8 xl:px-16 py-8`;

const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full uppercase tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;

const highlightGradientsCss = [
  css`
    background: rgb(19, 84, 182);
  `,
];

/**
 * 상품 가격 안내 
 * 공통 페이지 입니다. 
 */
export default () => {
  
  let history = useHistory();
  const alert = useAlert();
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  const doJoin = () => {
    console.log("회원가입");
    history.push("/sign_up");
  }

  return (
    <>
    <Pricing
      subheading={<Subheading>제품가격</Subheading>}
      heading={
        <>
          업계 최저가 <HighlightedText>월 만원</HighlightedText>
        </>
      }
      description={""}
      plans={[
        {
          name: "무료 체험",
          price: "0원",
          duration: "최초 가입후 30일, 가입 비 무료",
          features: ["회원 가입 후 30일 간 무료로 사용해 보세요"],
          buttonText: "회원가입"
        },
        {
          name: "30일 이용권",
          price: "10,000원",
          duration: "30일 (VAT 별도)",
          features: ["30일 이용권"],
          featured: true,
          buttonText: "결제"
        },
        {
          name: "180일 이용권",
          price: "57,000원",
          duration: "180일(5%할인, VAT별도)",
          features: ["5% 할인 된 금액으로 180일간 사용"],
          buttonText: "결제"
        }
      ]}
    />
     <PlanAction>
        <BuyNowButton css={highlightGradientsCss[0]} onClick={() => {doJoin();}}> 30일 무료체험 신청하기 </BuyNowButton>
      </PlanAction>
    </>
  );
}
